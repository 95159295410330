import React, { useState } from "react";
import { getDateFromTimestamp } from "../../common/DateAndTime";
import backendAPI from "../../../services/apiRequestService";
import { toast, Toaster } from "sonner";
import { maxFileSize } from "../../../routes/BaseUrl";
import FileUpload from "../../common/FileUpload";
import { Loader, Upload, X } from "lucide-react";
import { AxiosError } from "axios";

interface Evaluator {
  id: string;
  name: string;
  functionName: string;
  description: string;
  language: "PYTHON" | "MATLAB" | "CPP";
  fileName: string;
  status: string;
  createdAt: string;
}

interface ViewEvaluatorDetailsProps {
  evaluator: Evaluator;
  fileUpload?: boolean;
  onClose: (file: File | null) => void;
}

interface DetailItem {
  label: string;
  value: string | undefined | null;
}

const formatText = (text: string | undefined | null): string => {
  if (!text) return "";
  if (text === "PYTHON") return "Python";
  if (text === "MATLAB") return "MATLAB";
  if (text === "CPP") return "CPP";
  return text
};

type Detail = {
  label: string;
  value: string | undefined | null;
};

const DetailsItems = ({ label, value }: Detail) => {
  return (
    <div className="flex items-center">
      <div className="w-1/3 font-medium text-gray-700">
        {label}
      </div>
      <div className="w-2/3 p-2 border rounded-md bg-gray-50 text-gray-800">
        {formatText(value)}&nbsp;
      </div>
    </div>
  );
};



const ViewEvaluatorDetails: React.FC<ViewEvaluatorDetailsProps> = ({
  evaluator,
  fileUpload,
  onClose,
}) => {
  const [currentEvaluator, setCurrentEvaluator] = useState(evaluator);
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  if (!evaluator) return null;
  
  let statusValue;
  if (fileUpload) {
    statusValue = null;
  } else if (currentEvaluator.status === "FILE_UPLOAD") {
    statusValue = "FILE IS MISSING";
  } else {
    statusValue = currentEvaluator.status;
  }

  const details: DetailItem[] = [
    { label: "Name", value: currentEvaluator.name },
    { label: "Function", value: currentEvaluator.functionName },
    { label: "Description", value: fileUpload ? null : currentEvaluator.description },
    { label: "Language", value: currentEvaluator.language },
    {
      label: "Created At",
      value: fileUpload ? null : getDateFromTimestamp(parseInt(currentEvaluator.createdAt), true),
    },
    { label: "Status", value: statusValue },
    { label: "File", value: currentEvaluator.fileName },
  ];

  const uploadEvaluator = async () => {
    if (!file) {
      toast.error("Need file for registration.");
      return;
    }
    const evaluatorId = evaluator.id;
    const formData = new FormData();
    formData.append("evaluatorFile", file);
    try{
      setIsUploading(true);
      const response = await backendAPI.post(
        `/project-management/evaluators/${evaluatorId}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setIsUploading(false);
      setCurrentEvaluator(response.data)
      onClose(file)
      toast.success("Evaluator registered successfully.");
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        toast.error(
          String(error.response?.data?.message).includes("already exists") ? 
            "Evaluator Already Exists, Upload Different Evaluator" : error.response?.data?.message || 
          error.response?.data?.error || "Failed to upload evaluator. Please try again later."
        );
      } else {
        toast.error("Failed to upload evaluator. Please try again later.");
      }
      setIsUploading(false);
    }
  }

  const handleFileChange = (file: File | null): void => {
    const selectedFile = file;
    if (selectedFile) {
      if (selectedFile.size > maxFileSize) {
        toast.error(`File size exceeds the maximum allowed size of ${maxFileSize / (1024 * 1024)} MB.`);
        return;
      }
      setFile(selectedFile);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <Toaster richColors={true}/>
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl">
        <div className=" flex justify-between border-b pb-2 mb-4">
          <h2 className="text-lg font-medium">
            {fileUpload ? "Upload Evaluator" : "Evaluator Details"}
          </h2>
          <button
            onClick={() => onClose(file)}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="p-0.5" />
          </button>
        </div>
        <div className="space-y-4">

        { fileUpload ? <>
          {details.map((detail) => ( detail.value && 
            <div key={detail.label} className="flex items-center">
              <div className="w-1/3 font-medium text-gray-700">
                {detail.label}
              </div>
              <div className="w-2/3 p-2 border rounded-md bg-gray-50 text-gray-800">
                {formatText(detail.value)}&nbsp;
              </div>
            </div>
          ))}

          <div className=" flex ">
            <p className=" w-1/3 py-2 font-medium text-gray-700">File</p>
            <div className=" flex flex-col flex-1 gap-3">
              <div className={`flex-1 ${isUploading && "animate-pulse"}`}>
                <FileUpload onFileChange={handleFileChange}/> 
              </div>
              <div className=" w-full flex justify-end">
                <button onClick={uploadEvaluator} className={` ${isUploading && " pointer-events-none opacity-60"} transition-colors flex gap-2 p-1.5 pr-4 shadow-md bg-teal-600 text-white hover:bg-teal-700 rounded-lg `}>
                  {isUploading ? <Loader className=" p-1 animate-spin" /> : <Upload className=" p-1 " /> }
                  <p>Upload</p>
                </button>
              </div>
            </div>
          </div>
        </>

        : <>
          {details.map((detail) => <DetailsItems key={detail.label} label={detail.label} value={detail.value} />)}
        </>
        }
        </div>
      </div>
    </div>
  );
};

export default ViewEvaluatorDetails;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FolderClosed,
  Shield,
  Server,
  LayoutDashboard,
  Lock,
} from "lucide-react";
import { useAppSelector } from "../../redux/hooks";
import { getkc } from "../../services/KeycloakService";

const menuItems = [
  { path: "/projects", icon: FolderClosed, label: "Projects" },
  { path: "/evaluators", icon: Shield, label: "Evaluators" },
  { path: "/servers", icon: Server, label: "Servers" },
  { path: "/manage-access", icon: Lock, label: "Access Control" },
];

interface SidebarProps {
  isOpen: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen }) => {
  const location = useLocation();
  const activeTenant = useAppSelector((state) => state.tenant.active);
  const kc = getkc();

  let hasUserAdmin = false;
  if (
    kc &&
    kc.tokenParsed &&
    Array.isArray(kc.tokenParsed.tenant_roles) &&
    activeTenant
  ) {
    kc.tokenParsed.tenant_roles.forEach((roleObj: any) => {
      const rolesForTenant = roleObj[activeTenant];
      if (rolesForTenant && Array.isArray(rolesForTenant)) {
        if (rolesForTenant.includes("USER_ADMIN")) {
          hasUserAdmin = true;
        }
      }
    });
  }


  const filteredMenuItems = menuItems.filter((item) => {
    if (item.path === "/manage-access") {
      return hasUserAdmin;
    }
    return true;
  });

  return (
    <aside
      className={`p-2 min-h-screen h-full transition-all bg-white border-l ${
        isOpen ? "min-w-40 opacity-100" : "min-w-0 opacity-0"
      }`}
    >
      {isOpen && (
        <>
          <div className="flex items-center gap-2 text-lg font-semibold mt-4 mb-8">
            <LayoutDashboard className="w-5 h-5" /> Dashboard
          </div>
          <nav className="space-y-1.5">
            {filteredMenuItems.map((item) => {
              const Icon = item.icon;
              const isActive = location.pathname === item.path;
              return (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`flex items-center p-2 transition-all rounded-md whitespace-nowrap ${
                    isActive
                      ? "text-indigo-600 bg-indigo-100"
                      : "bg-white hover:bg-gray-100"
                  }`}
                >
                  <Icon className="w-5 h-5 mr-2" /> {item.label}
                </Link>
              );
            })}
          </nav>
        </>
      )}
    </aside>
  );
};

export default Sidebar;

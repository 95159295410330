import { ChevronDown, ChevronRight, Info } from "lucide-react";
import React, { useState, useRef } from "react";
import { ExtendedProjectData, ProjectType, TreeNodesType } from "../types";
import LogisticsNode from "./nodes/LogisticsNode";
import ConstellationNode from "./nodes/ConstellationNode";

interface TreeStructureProps {
  projectData: ExtendedProjectData;
  treeNodes: TreeNodesType;
  collapsedNodes: Record<string, boolean>;
  toggleNode: (node: string) => void;
  onFileChange: (files: any) => void;
  onTargetsChange: (value: number | undefined) => void;
  onManualModeChange: (isOn: boolean) => void;
  onManualValueChange: (field: string, value: string) => void;
}

const TreeStructure: React.FC<TreeStructureProps> = ({
  projectData,
  treeNodes,
  collapsedNodes,
  toggleNode,
  onFileChange,
  onTargetsChange,
  onManualModeChange,
  onManualValueChange,
}) => {
  const { projectType, activeNode } = projectData;
  const projectTypeKey = projectType as ProjectType;
//   const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const isManualMode = projectData.isManualMode || false;

  if (
    !projectTypeKey ||
    !treeNodes[projectTypeKey] ||
    treeNodes[projectTypeKey].length === 0
  ) {
    return null;
  }

  const manualValues = projectData.manualValues || {
    coverageRatio: "",
    target: "",
    satellite: "",
    };
    
const filesList = useRef<File[]>([]);
const handleFileChange = (file: any | null) => {
    if (file) {
      filesList.current.push(file[0]);
      
      if (filesList.current.length > 1) {
        onFileChange(filesList.current);
        
    }
  } else {
    onFileChange([]);
  }
};

  return (
    <div className="border-l-2 border-blue-200 pl-4 space-y-4 w-full mt-4">
      {treeNodes[projectTypeKey].map((node) => (
        <div key={node} className="relative w-full">
          <div className="flex items-center justify-between w-full pr-6 py-2">
            <div className="flex items-center gap-2">
              <button
                type="button"
                disabled={node.includes("coming soon")}
                onClick={() => toggleNode(node)}
                className={`relative text-sm font-medium flex items-center gap-2
                  ${
                    projectData.activeNode === node
                      ? "text-blue-700 font-semibold"
                      : node.includes("coming soon")
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-gray-700 hover:text-blue-500"
                  }`}
              >
                {projectData.activeNode === node && !collapsedNodes[node] ? (
                  <ChevronDown className="w-4 h-4 text-gray-600" />
                ) : (
                  <ChevronRight className="w-4 h-4 text-gray-600" />
                )}
                {node}
              </button>

              {/* {projectTypeKey === "SATELLITE_CONSTELLATION_OPTIMIZATION" &&
                node === "CONSTELLATION" && (
                  <div className="flex items-center ml-4">
                    <div className="relative flex items-center">
                      <span className="text-xs text-gray-500 mr-2">Manual</span>
                      <Info
                        className="w-3 h-3 text-gray-500 cursor-help mr-1"
                        onMouseEnter={() => setIsTooltipVisible(true)}
                        onMouseLeave={() => setIsTooltipVisible(false)}
                      />
                      {isTooltipVisible && (
                        <div className="absolute left-0 -top-8 w-48 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                          Enter parameters manually instead of uploading
                          configuration files
                        </div>
                      )}
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          checked={isManualMode}
                          onChange={() => onManualModeChange(!isManualMode)}
                          className="sr-only peer"
                        />
                        <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                  </div>
                )} */}
            </div>
          </div>

          {activeNode === node && !collapsedNodes[node] && (
            <div className="mt-2 pl-4 space-y-4 w-full">
              {projectTypeKey === "SATELLITE_CONSTELLATION_OPTIMIZATION" &&
                node === "CONSTELLATION" && (
                  <ConstellationNode
                    projectData={projectData}
                    onFileChange={onFileChange}
                    onTargetsChange={onTargetsChange}
                    onManualModeChange={onManualModeChange}
                    onManualValueChange={onManualValueChange}
                  />
                )}

              {projectTypeKey === "SUPPLY_CHAIN_OPTIMIZATION" && (
                <>
                  {node === "LOGISTICS" && (
                    <LogisticsNode
                      projectData={projectData}
                      onTreeFileChange={(file) => {
                        handleFileChange(file);
                      }}
                      onCostVectorChange={(file) => {
                        handleFileChange(file);
                      }}
                      onSitesChange={(value) => {
                        console.log(value);
                      }}
                      onProductsChange={(value) => {
                        console.log("Number of products changed:", value);
                      }}
                    />
                  )}
                  {node === "WAREHOUSE" && (
                    <div className="p-3 border border-gray-200 rounded-lg">
                      <p className="text-sm text-gray-600">
                        Warehouse configuration options will appear here.
                      </p>
                    </div>
                  )}
                  {node === "DEMAND" && (
                    <div className="p-3 border border-gray-200 rounded-lg">
                      <p className="text-sm text-gray-600">
                        Demand configuration options will appear here.
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TreeStructure;

import React, { useEffect, useRef, useState } from "react";
import { toast, Toaster } from "sonner";
import { setExecutionParams } from "../../../redux/executionSlice";
import { setGlobalCheck } from "../../../redux/globalCheckSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../store";
import { ExecuteModalProps, ExecutionParams } from "../../../types/types";
import FieldInfo from "./components/FieldInfo";
import { X } from "lucide-react";
import ToggleBtn from "../../common/button/ToggleBtn";

const allowedGATenant = ["Sky", "DesOpt", "QA" ];

const ExecuteModal: React.FC<
  ExecuteModalProps & { setPayload: (payload: any) => void }
> = ({ project, onClose, setIndex, setPayload }) => {
  const dispatch = useAppDispatch();
  const activeTenantId = useAppSelector((state) => state.tenant.active);
  const filled = localStorage.getItem('filled') === "true"
  const initialFieldValidity = {
    populationSize: filled,
    designVariables: filled,
    maxGenerations: filled,
    debsApproach: true,
    crossoverProbability: true,
    mutationProbability: true,
    deltaTheta: filled,
    functionToleranceLimit: filled,
    designVariableToleranceLimit: filled,
    trials: filled,
    lowerBound: true,
    upperBound: true,
  };

  const executionParams = useAppSelector((state: RootState) => state.execution);
  const globalCheck = useAppSelector((state: RootState) => state.globalCheck);

  const [params, setParams] = useState<ExecutionParams>({
    crossoverProbability: executionParams.crossoverProbability,
    mutationProbability: executionParams.mutationProbability,
    populationSize: executionParams.populationSize,
    designVariables: executionParams.designVariables,
    maxGenerations: executionParams.maxGenerations,
    deltaTheta: executionParams.deltaTheta,
    debsApproach: executionParams.debsApproach,
    functionToleranceLimit: executionParams.functionToleranceLimit,
    designVariableToleranceLimit: executionParams.designVariableToleranceLimit,
    typeOfOptimization: executionParams.typeOfOptimization || "continuous",
    lowerBound: executionParams.lowerBound,
    upperBound: executionParams.upperBound,
    trials: executionParams.trials,
  });

  const boundGlobalStatus = useRef<any>([
    globalCheck.lowerBoundChecked ?? true,
    globalCheck.upperBoundChecked ?? true
  ]);

  const [lowerBoundChecked, setLowerBoundChecked] = useState(globalCheck.lowerBoundChecked ?? true);
  const [upperBoundChecked, setUpperBoundChecked] = useState(globalCheck.upperBoundChecked ?? true);
  const [nextDisabled, setNextDisabled] = useState(!filled);

  const [fieldValidity, setFieldValidity] = useState(initialFieldValidity);
  const [useGA, setUseGA] = useState<boolean>(localStorage.getItem("useGA") == "true");

  useEffect(() => {
    setLowerBoundChecked(globalCheck.lowerBoundChecked ?? true);
    setUpperBoundChecked(globalCheck.upperBoundChecked ?? true);
  }, [globalCheck]);

  useEffect(()=>{
    if(localStorage.getItem('filled') !== "true"){
      const updatedFieldValidity = { ...initialFieldValidity };
      Object.keys(params).forEach((key) => {
        if (params[key as keyof ExecutionParams]) {
          updatedFieldValidity[key as keyof typeof initialFieldValidity] = true;
        }
      });
      setFieldValidity(updatedFieldValidity);
    }
  },[])

  const onBack = () => {
    setIndex(0);
    const allFieldsValid = Object.values(fieldValidity).every(Boolean);
    if (allFieldsValid) {
      localStorage.setItem("filled", 'true');
    }else{
      localStorage.removeItem("filled");
    }
  };

  const checkValidBound = (
    boundStatus: boolean,
    boundValue: string | undefined | null,
    boundName: string
  ) => {
    let designVariable: any = params.designVariables;
    designVariable = parseInt(designVariable);

    if (params.typeOfOptimization === "continuous" && boundValue) {
      const boundListStr = boundValue.split(",");
      const boundListNum = boundListStr.map(Number);
      let arr = [];
      if (boundStatus && boundListNum.length === 1) {
        try{
          arr = Array(designVariable).fill(boundListNum?.[0]);
          return arr;
        }catch(e){
          toast.info(`${boundName} : Invalid value`);
          return false;
        }
      }
      if (boundStatus && boundListNum.length !== 1) {
        toast.error(
          `${boundName} : If the Global is Checked, only a single value is allowed.`
        );
        return false;
      }
      if (boundListNum.length !== designVariable) {
        toast.error(`Missing ${boundName}'s value(s) `);
        return false;
      }
      arr = boundListNum.slice(0, designVariable);
      return arr;
    } else {
      toast.error(`${boundName}'s value(s) is/are missing.`);
      return false;
    }
  };

  const handleNext = async (
    e?: React.FormEvent<HTMLFormElement>
  ): Promise<boolean> => {
    if (e) {
      e.preventDefault();
    }
    const serverId = localStorage.getItem("selectedServer");
    let lowerBound, upperBound;
    if (params.typeOfOptimization === "continuous") {
      lowerBound = checkValidBound(
        boundGlobalStatus.current[0],
        params.lowerBound,
        "Lower Bound"
      );
      upperBound = checkValidBound(
        boundGlobalStatus.current[1],
        params.upperBound,
        "Upper Bound"
      );
      if (lowerBound === false || upperBound === false) return false;
    }
    const payload = {
      server: {
        id: serverId,
      },
      qieoConfig: {
        populationSize:
          params.populationSize !== undefined
            ? Number(params.populationSize)
            : undefined,
        typeOfOptimization: params.typeOfOptimization,
        maxGenerations:
          params.maxGenerations !== undefined
            ? Number(params.maxGenerations)
            : undefined,
        designVariables:
          params.designVariables !== undefined
            ? Number(params.designVariables)
            : undefined,
        ...(params.typeOfOptimization === "continuous"
          ? {
            lowerBounds: lowerBound,
            upperBounds: upperBound,
          }
          : {}),
        debsApproach: params.debsApproach,
        ...(useGA ? {
          crossoverProbability: params.crossoverProbability !== undefined ? Number(params.crossoverProbability) : undefined,
          mutationProbability: params.mutationProbability !== undefined ? Number(params.mutationProbability) : undefined
        }
          : {
            deltaTheta:
              params.deltaTheta !== undefined
                ? Number(params.deltaTheta)
                : undefined,
          }),
        trials: params.trials !== undefined ? Number(params.trials) : undefined,
        functionToleranceLimit:
          params.functionToleranceLimit !== undefined
            ? Number(params.functionToleranceLimit)
            : undefined,
        designVariableToleranceLimit:
          params.designVariableToleranceLimit !== undefined
            ? Number(params.designVariableToleranceLimit)
            : undefined,
      },
      executionDetails: {
        optimizer: useGA ? "ga" : "qea",
      },
    };
    setPayload(payload);
    return true;
  };

  const checkInputContraints = (
    e: React.ChangeEvent<HTMLInputElement>,
    payloadValue: string,
    minVal: number,
    maxVal: number
  ): boolean => {
    const val = parseFloat(payloadValue);
    if (isNaN(val) || val < minVal || val > maxVal) {
      e.target.classList.add("border-red-500");
      return false;
    } else {
      e.target.classList.remove("border-red-500");
      return true;
    }
  };

  const checkFieldValidity = (name: string, validCheck:boolean) => {
    setFieldValidity((prev) => {
      const updatedValidity = { ...prev, [name]: validCheck };
      const allValid = Object.values(updatedValidity).every(Boolean);
      if (!allValid) {
        setNextDisabled(true);
      } else {
        setNextDisabled(false);
      }
      return updatedValidity;
    });
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    let validCheck = false;
    switch (name) {
      case "crossoverProbability": case "mutationProbability":
        if (useGA) {
          validCheck = checkInputContraints(e, value, 0, 1);
        }
        break;
      case "populationSize":
        validCheck = checkInputContraints(e, value, 10, 100000);
        break;
      case "designVariables":
        validCheck = checkInputContraints(e, value, 1, 5e4);
        break;
      case "maxGenerations":
        validCheck = checkInputContraints(e, value, 10, 50000);
        break;
      case "deltaTheta":
        if (!useGA) {
          validCheck = checkInputContraints(e, value, -Infinity, 1);
        }
        break;
      case "functionToleranceLimit":
      case "designVariableToleranceLimit":
        validCheck = checkInputContraints(
          e,
          value,
          0.00000000000000000001,
          0.001
        );
        break;

      case "trials":
        validCheck = checkInputContraints(e, value, 1, 100);
        break;
      case "lowerBound":
        validCheck = true;
        break;
      case "upperBound":
        validCheck = true;
        break;
    }

  checkFieldValidity(name, validCheck);
  setParams((prev) => ({
    ...prev,
    [name]: value,
  }));
  dispatch(setExecutionParams({ ...params, [name]: value }));
};

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setParams((prev) => ({
      ...prev,
      typeOfOptimization: e.target
        .value,
    }));
    dispatch(setExecutionParams({ ...params, typeOfOptimization: e.target.value }));
  };

  const handleBoundGlobal = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value, checked } = e.target;
    if (value === "lowerBoundCheckbox") {
      setLowerBoundChecked(!lowerBoundChecked);
      boundGlobalStatus.current[0] = checked;
      dispatch(setGlobalCheck({ lowerBoundChecked: checked, upperBoundChecked }));
    }
    if (value === "upperBoundCheckbox") {
      setUpperBoundChecked(!upperBoundChecked);
      boundGlobalStatus.current[1] = checked;
      dispatch(setGlobalCheck({ lowerBoundChecked, upperBoundChecked: checked }));
    }
  };

  const toggleGA = (isActive:boolean) => {
    localStorage.setItem("useGA", (isActive).toString());
    checkFieldValidity("crossoverProbability", params.crossoverProbability? true: !isActive);
    checkFieldValidity("mutationProbability",params.mutationProbability ? true : !isActive);

    checkFieldValidity("deltaTheta",params.deltaTheta ? true : isActive);
    if(nextDisabled){
      const updatedParams = { ...params };
      Object.keys(fieldValidity).forEach((key) => {
        if (!fieldValidity[key as keyof typeof fieldValidity]) {
          (updatedParams as any)[key] = null;
        }
      });
      setParams(updatedParams);
    }
    setUseGA(isActive);
  }

  const handleToggleBtn = (isActive:boolean) => {
    setParams((prev) => ({...prev, debsApproach: isActive.toString()}));
    dispatch(setExecutionParams({ ...params, debsApproach: isActive.toString() }));
  }
  return (
    <>
      <Toaster richColors expand={true} />
      <div className="p-6 border-b flex justify-between items-center">
        <button
          type="button"
          onClick={onBack}
          className="text-gray-500 hover:text-gray-700 text-2xl mr-5"
        >
          ←
        </button>
        <h2 className="text-xl font-bold">Execute {project.name}</h2>
        {allowedGATenant.map(tenant => tenant.toLowerCase()).includes(activeTenantId.toLowerCase()) && 
          <div className="flex items-center justify-end gap-3 flex-1 mr-14 select-none">
            <p className="cursor-pointer">Use GA</p>
            <ToggleBtn enabled={useGA} onChange={toggleGA} />
          </div>
        }
        <button
          type="button"
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 text-2xl"
        >
          <X className=" select-none" />
        </button>
      </div>

      <div className=" p-6">
        <form onSubmit={handleNext} className="flex flex-col justify-between gap-2" >
          <div className="flex flex-col gap-3 h-96 overflow-y-auto">
            {[
              {
                label: "Population Size",
                name: "populationSize",
                desc: (
                  <p>
                    For any evolutionary algorithms, the number of initial
                    points should be supplied as user input. The input value
                    should be ranging from{" "}
                    <span className=" text-red-500 font-bold">
                      10 to 100,000
                    </span>{" "}
                    and must be <strong>an Integer</strong>. Based on the
                    complexity of the problem, users can increase or decrease
                    the number of chromosomes, which greatly effects the
                    solution accuracy and time taken to complete the
                    optimization problem.
                  </p>
                ),
              },
              {
                label: "Design Variables",
                name: "designVariables",
                desc: (
                  <p>
                    Design variables/ unknowns are the output from the
                    optimization problem.{" "}
                    <i>
                      The function value is calculated based on these variables.{" "}
                    </i>
                    The user must specify the number of unknowns for the current
                    function that is being optimized. For the function
                    optimization cases, this variable acts as dimensionality of
                    the problem. The entered input must be ranging from{" "}
                    <span className=" text-red-500 font-bold">1 to 5e4</span>{" "}
                    and it is <strong>an Integer</strong>.
                  </p>
                ),
              },
              {
                label: "Maximum Number of Generations",
                name: "maxGenerations",
                desc: (
                  <p>
                    This is one of the termination criteria. The moment the
                    optimizer reaches this value of maximum number of
                    generations, it terminates and provides the solution. This
                    integer input must be from{" "}
                    <span className=" text-red-500 font-bold">
                      10 to 50,000.
                    </span>
                  </p>
                ),
              },
              {
                label: "Trials",
                name: "trials",
                desc: (
                  <p>
                    If the user wants to run the same optimization problem for
                    more than once, then the user just needs to specify the
                    number of the different experiments as input. This integer
                    input should range from{" "}
                    <span className=" text-red-500 font-bold">1 to 100.</span>
                  </p>
                ),
              },
              {
                label: "Debs Approach",
                name: "debsApproach",
                fieldType: "boolean",
                desc: (<p>By enabling, this helps find multiple solutions that balance different goals in a problem. It sorts solutions based on how good they are and keeps a variety of options to avoid focusing on just one.</p>),
              },
              ...(useGA
                ? [
                  {
                    label: "Crossover Probability",
                    name: "crossoverProbability",
                    desc: (
                      <p>
                        The probability of crossover in the genetic algorithm. The
                        input value should be ranging from
                        <span className=" text-red-500 font-bold"> 0 to 1</span> and
                        must be <strong>a Float</strong>.
                      </p>
                    ),
                  },
                  {
                    label: "Mutation Probability",
                    name: "mutationProbability",
                    desc: (
                      <p>
                        The probability of mutation in the genetic algorithm. The input value should be ranging from
                        <span className="text-red-500 font-bold"> 0 to 1</span> and must be <strong>a Float</strong>.
                      </p>
                    ),
                  },
                ]
                : [
                  {
                    label: "Theta",
                    name: "deltaTheta",
                    desc: (
                      <p>
                        This input value is the maximum rotation value of the gene
                        in each generation.{" "}
                        <span className=" text-red-500 font-bold">
                          The maximum value is set as one
                        </span>
                        . You can provide <i>-negative values</i> as well, to
                        understand the algorithm.
                      </p>
                    ),
                  },
                ]),
              {
                label: "Function Tolerance",
                name: "functionToleranceLimit",
                desc: (
                  <p>
                    This is one of the termination criteria. The optimizer
                    stops, if the change of function value between the average
                    value of last &apos;n&apos; generations and the current
                    generation is within the given tolerance limit.{" "}
                    <i>
                      {" "}
                      In the current formulation, n is fixed, and it is equal to
                      5{" "}
                    </i>
                    , i.e., the average function value of last 5 generations is
                    compared with the current generation. Ideally,{" "}
                    <span className=" text-red-500 font-bold">
                      1e-6 i.e 0.000001
                    </span>{" "}
                    is a suitable input value.
                  </p>
                ),
              },
              {
                label: "Design Variable Tolerance",
                name: "designVariableToleranceLimit",
                desc: (
                  <p>
                    This is one of the termination criteria. The optimizer stops
                    if the maximum change in design variable between the last
                    two generations is within the given tolerance limit.
                    Ideally,{" "}
                    <span className=" text-red-500 font-bold">
                      1e-9 is a suitable value.
                    </span>
                  </p>
                ),
              },
            ].map(({ label, fieldType, name, desc }) => (
              <>{fieldType === "boolean" ?
                <div className=" flex items-center justify-between">
                  <div className={` flex ${desc ? "justify-between" : "justify-end"} gap-2 items-center`} >
                    <p className="text-gray-700">{label}</p>
                    {desc && <FieldInfo desc={desc} />}
                  </div>
                  <div className="flex items-center gap-2 pl-0 p-2 w-72">
                    <ToggleBtn enabled={params[name as keyof ExecutionParams] === "true"} onChange={handleToggleBtn} />
                  </div>
                </div> :
                <div className={`flex items-center`} key={name}>
                  <label className="block text-gray-700 w-fit whitespace-nowrap mr-2 ">
                    {label}
                  </label>
                  <div className={`flex ${desc ? "justify-between" : "justify-end"} gap-2 items-center w-full`} >
                    {desc && <FieldInfo desc={desc} />}
                    <input
                      type="text"
                      name={name}
                      placeholder={`Enter ${label?.toLowerCase()}`}
                      className="p-2 border rounded-md w-72"
                      value={params[name as keyof ExecutionParams] ?? ""}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              }
                {["deltaTheta", "DesignVar_Tolerance_Limit", "trials", "mutationProbability"].includes(name) && (
                  <div className="border-b w-full mx-auto"></div>
                )}
              </>
            ))}

            <div className="flex items-center justify-between ">
              <p className="block text-gray-700 w-1/3 py-2 ">
                Type of Optimization
              </p>
              <div className="w-72 flex justify-start gap-14">
                {[
                  {
                    name: "continuous",
                    label: "Continuous",
                    desc: (
                      <div>
                        The lowest limit is{" "}
                        <span className=" text-red-500 font-bold">-1e6.</span>
                        <ol className="list-disc list-inside text-left">
                          <li>
                            Enter multiple values using &lsquo;,&lsquo; eg.{" "}
                            <span className=" text-green-500 font-bold">
                              3, 4, 2.2, 2
                            </span>
                          </li>
                          <li>
                            Bound count must be equal to the value of Design
                            Variable
                          </li>
                          <li>✅ Global - Single value is allowed</li>
                        </ol>
                      </div>
                    ),
                  },
                  {
                    name: "Discrete",
                    label: "Binary",
                    desc: (
                      <div>
                        The highest limit is{" "}
                        <span className=" text-red-500 font-bold">1e6.</span>
                        <ol className="list-disc list-inside text-left">
                          <li>
                            Enter multiple values using &lsquo;,&lsquo; eg.{" "}
                            <span className=" text-green-500 font-bold">
                              3, 4, 2.2, 2
                            </span>
                          </li>
                          <li>
                            Bound count must be equal to the value of Design
                            Variable
                          </li>
                          <li>✅ Global - Single value is allowed</li>
                        </ol>
                      </div>
                    ),
                  },
                ].map((type) => (
                  <label
                    className={`flex items-center cursor-pointer `}
                    key={type.name}
                  >
                    <input
                      type="radio"
                      name="typeOfOptimization"
                      value={type.name}
                      checked={params.typeOfOptimization === type.name}
                      onChange={handleRadioChange}
                      className={`mr-2`}
                    />
                    {type.label}
                  </label>
                ))}
              </div>
            </div>

            {params.typeOfOptimization === "continuous" && (
              <>
                {[
                  {
                    name: "lowerBound",
                    label: "Lower Bound",
                    checked: lowerBoundChecked,
                    desc: (
                      <div>
                        The lowest limit is{" "}
                        <span className=" text-red-500 font-bold">-1e6.</span>
                        <ol className="list-disc list-inside text-left">
                          <li>
                            Enter multiple values using &lsquo;,&lsquo; eg.{" "}
                            <span className=" text-green-500 font-bold">
                              3, 4, 2.2, 2
                            </span>
                          </li>
                          <li>
                            Bound count must be equal to the value of Design
                            Variable
                          </li>
                          <li>✅ Global - Single value is allowed</li>
                        </ol>
                      </div>
                    ),
                  },
                  {
                    name: "upperBound",
                    label: "Upper Bound",
                    checked: upperBoundChecked,
                    desc: (
                      <div>
                        The highest limit is{" "}
                        <span className=" text-red-500 font-bold">1e6.</span>
                        <ol className="list-disc list-inside text-left">
                          <li>
                            Enter multiple values using &lsquo;,&lsquo; eg.{" "}
                            <span className=" text-green-500 font-bold">
                              3, 4, 2.2, 2
                            </span>
                          </li>
                          <li>
                            Bound count must be equal to the value of Design
                            Variable
                          </li>
                          <li>✅ Global - Single value is allowed</li>
                        </ol>
                      </div>
                    ),
                  },
                ].map(({ name, label, checked, desc }) => (
                  <div className="flex items-center " key={name}>
                    <label className="block text-gray-70 whitespace-nowrap mr-2">
                      {label}
                    </label>
                    <div
                      className={`flex ${name ? "justify-between" : "justify-end"
                        } gap-2 items-center w-full`}
                    >
                      <FieldInfo desc={desc} />
                      <div className=" flex items-center w-72">
                        <input
                          type="text"
                          id={name}
                          name={name}
                          placeholder={`Enter ${label}`}
                          className=" p-2 border rounded-md focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
                          value={params[name as keyof ExecutionParams] ?? ""}
                          onChange={handleInputChange}
                        />

                        <div className=" flex gap-2 ml-2 select-none justify-center items-center">
                          <input
                            id={name + "Checkbox"}
                            type="checkbox"
                            onChange={handleBoundGlobal}
                            checked={checked}
                            className="focus:outline outline-green-500 h-fit"
                            value={name + "Checkbox"}
                          />
                          <label
                            htmlFor={name + "Checkbox"}
                            className=" cursor-pointer"
                          >
                            Global
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              onClick={async () => {
                const formValid = await handleNext();
                if (formValid) {
                  setIndex(2);
                  localStorage.setItem("filled", 'true')
                }
              }}
              className={`rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 py-2 px-6 flex justify-between gap-4 items-center ${nextDisabled
                ? "bg-gray-400 text-black cursor-not-allowed opacity-55"
                : "bg-teal-600 hover:bg-teal-700 text-white flex items-center gap-2"
                }`}
              disabled={nextDisabled}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ExecuteModal;

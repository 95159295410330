export const getDate = (date: number) => new Date(date).toLocaleDateString()
export const getTime = (date: number) => new Date(date).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })

export const getDateFromTimestamp = (date: number | string, showTime = false, showSec = false) => {
	const options: Intl.DateTimeFormatOptions = {
		day: "numeric",
		month: "short",
		year: "numeric",
		...(showTime && {
			hour: "2-digit",
			minute: "2-digit",
			...(showSec && {second: "2-digit"}),
			hour12:  true, 
		})
	};
	const formatedDate = new Date(date).toLocaleString("en-US", options);
	return formatedDate
};

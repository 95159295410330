import { useEffect, useState } from "react";
import { BrowserRouter} from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import { keyInit } from "./services/KeycloakService";

function App() {
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    if(window.location.href.includes("/terms")){
      return null;
    }
    keyInit()
      .then((success) => {
        if (success) {
          setAuth(true);
        }
      })
      .catch((error) => {
        console.error("Keycloak initialization failed", error);
      });
  }, []);

  return (
    <BrowserRouter
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath:true,
      }}>
      <div className=" bg-white min-h-screen">
        <AppRoutes auth={auth} />
      </div>
    </BrowserRouter>
  );
}

export default App;

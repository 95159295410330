import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TenantState {
  active: string;
}
const initialState: TenantState = {
  active: "",
};

const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    updateActiveTenant: (state, action:PayloadAction<string>) => {
      state.active = action.payload;
    },
  },
});

export const { updateActiveTenant } = tenantSlice.actions;

export default tenantSlice.reducer;

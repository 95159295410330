import React, { useState, useEffect } from "react";
import { ExtendedProjectData } from "../../types";
import SingleFileUpload from "../../../../common/SingleFileUpload";
import { Info } from "lucide-react";

interface ConstellationNodeProps {
  projectData: ExtendedProjectData;
  onFileChange: (files: any) => void;
  onTargetsChange: (value: number | undefined) => void;
  onManualModeChange: (isOn: boolean) => void;
  onManualValueChange: (field: string, value: string) => void;
}

const ConstellationNode: React.FC<ConstellationNodeProps> = ({
  projectData,
  onFileChange,
  onTargetsChange,
  onManualModeChange,
  onManualValueChange,
}) => {
  const [manualTooltip, setManualTooltip] = useState(false);
  const [targetsTooltip, setTargetsTooltip] = useState(false);
  const [uploadTooltip, setUploadTooltip] = useState(false);
  const [coverageTooltip, setCoverageTooltip] = useState(false);
  const [targetTooltip, setTargetTooltip] = useState(false);
  const [satelliteTooltip, setSatelliteTooltip] = useState(false);

  const isManualMode = projectData.isManualMode || false;
  const manualValues = projectData.manualValues || {
    coverageRatio: "",
    target: "",
    satellite: "",
  };

  const tooltips = [
    { state: manualTooltip, setState: setManualTooltip },
    { state: targetsTooltip, setState: setTargetsTooltip },
    { state: uploadTooltip, setState: setUploadTooltip },
    { state: coverageTooltip, setState: setCoverageTooltip },
    { state: targetTooltip, setState: setTargetTooltip },
    { state: satelliteTooltip, setState: setSatelliteTooltip },
  ];

  
  tooltips.forEach(({ state, setState }) => {
    useEffect(() => {
      if (state) {
        const timer = setTimeout(() => setState(false), 3000);
        return () => clearTimeout(timer);
      }
    }, [state]);
  });

  const handleToggleChange = () => {
    onManualModeChange(!isManualMode);
    };
    
  const handleFileChange = (file: File | null) => {
    if (file) {
      onFileChange([file]);
    } else {
      onFileChange([]);
    }
  };

  return (
    <div className="space-y-4">
      <div>
        <span className="flex items-center mb-1">
          <span className="text-sm font-medium text-gray-700">
            Number of Satellites
          </span>
          <Info
            className="ml-1 w-4 h-4 text-gray-500 cursor-help"
            onMouseEnter={() => setTargetsTooltip(true)}
            onMouseLeave={() => setTargetsTooltip(false)}
          />
        </span>
        {targetsTooltip && (
          <div className="absolute mt-1 w-48 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
            Total number of satellites
          </div>
        )}
        <input
          type="number"
          min={1}
          placeholder="e.g. 5"
          className="w-full p-2 border border-gray-300 rounded-lg text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
          value={projectData.numberOfTargets || ""}
          onChange={(e) =>
            onTargetsChange(parseInt(e.target.value, 10) || undefined)
          }
        />
      </div>

      {/* <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <span className="text-sm font-medium text-gray-700">
            Manual Input
          </span>
          <div className="relative">
            <Info
              className="w-4 h-4 text-gray-500 cursor-help"
              onMouseEnter={() => setManualTooltip(true)}
              onMouseLeave={() => setManualTooltip(false)}
            />
            {manualTooltip && (
              <div className="absolute left-6 -top-2 w-48 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                Switch to manually enter values instead of uploading files
              </div>
            )}
          </div>
        </div>
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            checked={isManualMode}
            onChange={handleToggleChange}
            className="sr-only peer"
          />
          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
        </label>
      </div> */}

      {isManualMode ? (
        <div className="space-y-3">
          <div>
            <span className="flex items-center mb-1">
              <span className="text-sm font-medium text-gray-700">
                Coverage Ratio
              </span>
              <Info
                className="ml-1 w-4 h-4 text-gray-500 cursor-help"
                onMouseEnter={() => setCoverageTooltip(true)}
                onMouseLeave={() => setCoverageTooltip(false)}
              />
            </span>
            {coverageTooltip && (
              <div className="absolute mt-1 w-48 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                The ratio of coverage for your constellation
              </div>
            )}
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-lg text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
              value={manualValues.coverageRatio}
              onChange={(e) =>
                onManualValueChange("coverageRatio", e.target.value)
              }
              placeholder="Enter coverage ratio"
            />
          </div>
          <div>
            <span className="flex items-center mb-1">
              <span className="text-sm font-medium text-gray-700">Target</span>
              <Info
                className="ml-1 w-4 h-4 text-gray-500 cursor-help"
                onMouseEnter={() => setTargetTooltip(true)}
                onMouseLeave={() => setTargetTooltip(false)}
              />
            </span>
            {targetTooltip && (
              <div className="absolute mt-1 w-48 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                Target specifications for the constellation
              </div>
            )}
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-lg text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
              value={manualValues.target}
              onChange={(e) => onManualValueChange("target", e.target.value)}
              placeholder="Enter target"
            />
          </div>
          <div>
            <span className="flex items-center mb-1">
              <span className="text-sm font-medium text-gray-700">
                Satellite
              </span>
              <Info
                className="ml-1 w-4 h-4 text-gray-500 cursor-help"
                onMouseEnter={() => setSatelliteTooltip(true)}
                onMouseLeave={() => setSatelliteTooltip(false)}
              />
            </span>
            {satelliteTooltip && (
              <div className="absolute mt-1 w-48 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                Satellite specifications for the constellation
              </div>
            )}
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-lg text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
              value={manualValues.satellite}
              onChange={(e) => onManualValueChange("satellite", e.target.value)}
              placeholder="Enter satellite"
            />
          </div>
        </div>
      ) : (
        <div>
          <span className="flex items-center mb-1">
            <span className="text-sm font-medium text-gray-700">
              Upload Target Files
            </span>
            <Info
              className="ml-1 w-4 h-4 text-gray-500 cursor-help"
              onMouseEnter={() => setUploadTooltip(true)}
              onMouseLeave={() => setUploadTooltip(false)}
            />
          </span>
          {uploadTooltip && (
            <div className="absolute mt-1 w-48 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
              Upload files containing your target configurations
            </div>
          )}
          <SingleFileUpload
            onFileChange={handleFileChange}
            accept=".csv,.json,.txt"
          />
        </div>
      )}
    </div>
  );
};

export default ConstellationNode;

import { AlertCircle, AlertTriangleIcon, Plus } from "lucide-react";
import { ReactNode } from "react";

const SupportMail = () => {
  return <div className="text-center text-sm p-2">
    <a
      href="mailto:support@bosonqpsi.com"
      className="underline text-red-500"
    >
      support@bosonqpsi.com
    </a>
  </div>
}

interface EmptyListProps {
  selector: number | string;
  logo?: ReactNode;
  title?: any;
  subtitle?: any;
  showSupportMail?: boolean;
  button?: string | null;
  onClick?: () => void;
}

const VisualElement = ({
  logo,
  title,
  subtitle,
  showSupportMail,
  button = null,
  onClick
}: any) => {
  return <div className="flex flex-col items-center justify-center mt-16 text-gray-800 opacity-80  mb-10">
    <div className=" flex items-center justify-between opacity-40 shadow-inner rounded-full">{logo}</div>
    <h1 className="text-xl font-semibold mb-2 mt-8">{title}</h1>
    <p className="text-center text-sm px-4">
      {subtitle}
    </p>
    {showSupportMail && <SupportMail />}
    {button &&
      <div className=" mt-8">
        <button
          type="button"
          className="bg-teal-600 text-white hover:bg-teal-700 gap-3 px-4 py-2 rounded-md flex items-center"
          onClick={onClick}
        >
          <Plus size={18} className=" pointer-events-none" /> {button}
        </button>
      </div>
    }
  </div>
}


const EmptyList = ({
  selector,
  logo,
  title,
  subtitle,
  showSupportMail,
  button,
  onClick
}: EmptyListProps) => {

  return (<>
    {{
      0: <div className=" opacity-60">
        <table className=" w-full animate-pulse" cellPadding={15}>
          <tbody>
            <tr className="bg-gray-200">
              <th><p className=" max-w-32 h-3 rounded-full bg-slate-300"></p></th>
              <td><p className=" max-w-28 min-w-6 h-3 rounded-full bg-slate-400"></p></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr className="bg-gray-100">
              <th><p className=" w-48 h-3 rounded-full bg-slate-300"></p></th>
              <td></td>
              <td><p className=" max-w-14 h-3 rounded-full bg-slate-400"></p></td>
              <td></td>
              <td className=" flex justify-end items-center"><p className=" w-8 h-3 rounded-full bg-slate-300"></p></td>
            </tr>
            <tr className="bg-gray-200 border-b border-gray-300">
              <th></th>
              <td><p className=" max-w-48 h-3 rounded-full bg-slate-300"></p></td>
              <td></td>
              <td><p className=" max-w-48 h-3 rounded-full bg-slate-300"></p></td>
              <td></td>
            </tr>
            <tr className="bg-gray-100">
              <th></th>
              <td></td>
              <td><p className=" max-w-48 h-3 min-w-20 rounded-full bg-slate-300"></p></td>
              <td></td>
              <td><p className=" w-48 h-3 rounded-full bg-slate-300"></p></td>
            </tr>
            <tr className="bg-gray-200">
              <th><p className=" w-36 h-3 rounded-full bg-slate-400"></p></th>
              <td></td>
              <td><p className=" max-w-24 h-3 rounded-full bg-slate-400"></p></td>
              <td></td>
              <td className=" flex justify-end items-center"><p className=" max-w-48 min-w-8 h-3 rounded-full bg-slate-300"></p></td>
            </tr>
          </tbody>
        </table>
      </div>,
      "noTenant": <VisualElement
        logo={<AlertCircle className="w-16 h-16 text-red-500 mb-4" />}
        title={"No Tenant Associated"}
        subtitle={"You are not associated with any tenant. Please contact the administrator for assistance."}
        showSupportMail={true}
      />,
      200: <></>,
      404: <VisualElement
        logo={logo}
        title={title}
        subtitle={subtitle}
        button={button}
        showSupportMail={showSupportMail}
        onClick={onClick}
      />,
      500: <VisualElement
        logo={<AlertTriangleIcon size={50} className="text-gray-800" />}
        title="Sorry... It's not you. It's us."
        subtitle={<span>We are experiencing an internal server problem. Please try again later</span>}
        showSupportMail={true}
      />
    }[selector]}
  </>
  );
};

export default EmptyList;

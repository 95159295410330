
import {
  LucideIcon,
  Sigma,
  Satellite,
  Truck,
} from "lucide-react";
import React from "react";
import { ExtendedProjectData, ProjectType } from "../types";

interface SimulationType {
  name: ProjectType | string;
  label: string;
  icon: LucideIcon;
  disabled: boolean;
}

interface SimulationTypeSelectorProps {
  projectData: ExtendedProjectData;
  onSelect: (typeName: string) => void;
}

const SimulationTypeSelector: React.FC<SimulationTypeSelectorProps> = ({
  projectData,
  onSelect,
}) => {
  const simulationTypes: SimulationType[] = [
    
    {
      name: "GENERAL_OPTIMIZATION",
      label: "General",
      icon: Sigma,
      disabled: false,
    },
    {
      name: "SATELLITE_CONSTELLATION_OPTIMIZATION",
      label: "Sat. Opt.",
      icon: Satellite,
      disabled: false,
    },
    {
      name: "SUPPLY_CHAIN_OPTIMIZATION",
      label: "Supply Chain",
      icon: Truck,
      disabled: false,
    },
  ];

  return (
    <div>
      <label htmlFor="type" className="block mb-2 text-sm font-medium">
        Optimization Type
      </label>
      <div className="flex gap-4 flex-wrap">
        {simulationTypes.map((type) => {
          const IconComponent = type.icon;
          return (
            <button
              key={type.name}
              id="type"
              type="button"
              disabled={type.disabled}
              className={`select-none p-4 border rounded-md text-sm min-w-20 w-fit h-fit
                ${type.disabled && "opacity-50 cursor-not-allowed bg-gray-100"}
                ${
                  !type.disabled && projectData.projectType === type.name
                    ? "border-blue-500 bg-teal-50"
                    : "border-gray-200 hover:border-blue-200"
                }`}
              onClick={() => onSelect(type.name)}
            >
              <div className="flex flex-col items-center gap-2">
                <IconComponent className="w-6 h-6" />
                <span>{type.label}</span>
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default SimulationTypeSelector;

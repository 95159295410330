import React, { useEffect, useState } from "react";
import { formatLanguage } from "../../common/Formatter";
import { Evaluator } from "../../../types/types";
import { getDateFromTimestamp } from "../../common/DateAndTime";
import Table from "../../common/Table";
import DownloadBtn from "../../common/button/DownloadBtn";
import backendAPI from "../../../services/apiRequestService";
import { toast } from "sonner";
import { X, Check, ShieldAlert } from "lucide-react";
import { AxiosError } from "axios";
import EmptyList from "../../common/EmptyList";
import { useAppSelector } from "../../../redux/hooks";

interface PendingProp {
  reloadEval:()=>void;
  reloadPending:boolean;
}

const checkApprovalStatus = ( currentStatus: "APPROVED" | "REJECTED") => {
  switch (currentStatus){
    case "APPROVED":
    return { 
      text:"Approved",
      style: "bg-green-100 text-green-700", 
      icon: <Check size={15} />
    };
    case "REJECTED":
    return { 
      text:"Rejected",
      style: "bg-red-100 text-red-700", 
      icon:<X size={15} /> 
    };
  }
}

const ActionButtons = ({ evaluator, updatedStatuses, confirmationId, pendingStatus, handleEvaluatorStatus ,setConfirmationId, setPendingStatus }: any) => {
  const currentStatus = updatedStatuses[evaluator.id] || evaluator.status;

  if (currentStatus === "APPROVED" || currentStatus === "REJECTED") {
    return (
      <div
        className={`px-3 py-1.5 rounded-md text-sm font-medium ${checkApprovalStatus(currentStatus).style}`}
      >
        <div className="flex items-center gap-1">
          {checkApprovalStatus(currentStatus).icon}
          <span>
            {checkApprovalStatus(currentStatus).text}
          </span>
        </div>
      </div>
    );
  }

  if (confirmationId === evaluator.id) {
    return (
      <div className="flex items-center gap-3 w-full">
        <button
          onClick={(e) => {
            (e.target as any).value = pendingStatus;
            handleEvaluatorStatus(e, evaluator.id);
          }}
          className={`px-4 py-1.5 text-sm font-medium rounded-md transition-colors duration-200 flex items-center gap-1 ${
            pendingStatus === "APPROVED"
              ? "bg-green-600 hover:bg-green-700 text-white"
              : "bg-red-600 hover:bg-red-700 text-white"
          }`}
        >
          <span className="text-md">
            {checkApprovalStatus(pendingStatus).icon}
          </span>
          <span>
            Confirm {checkApprovalStatus(pendingStatus).text}
          </span>
        </button>
        <button
          onClick={() => {
            setConfirmationId(null);
            setPendingStatus(null);
          }}
          className="px-4 py-1.5 text-sm font-medium bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors duration-200"
        >
          Cancel
        </button>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-3 w-full">
      <button
        onClick={() => {
          setConfirmationId(evaluator.id);
          setPendingStatus("APPROVED");
        }}
        className="px-4 py-1.5 bg-green-100 text-green-700 rounded-md hover:bg-green-200 transition-colors duration-200 font-medium text-sm flex items-center gap-1"
      >
        <Check size={15} />
        <span>Approve</span>
      </button>
      <button
        onClick={() => {
          setConfirmationId(evaluator.id);
          setPendingStatus("REJECTED");
        }}
        className="p-2 py-1 pl-3 bg-red-100 text-red-700 rounded-md hover:bg-red-200 transition-colors duration-200 font-medium text-sm flex items-center gap-1"
      >
        <X size={15} />
        <span>Reject</span>
      </button>
    </div>
  );
};

const PendingEvaluator = ({reloadEval, reloadPending}:PendingProp) => {
  const COMPONENT_PREFIX = "Pending Evaluator";
  const [confirmationId, setConfirmationId] = useState<string | null>(null);
  const [pendingStatus, setPendingStatus] = useState<string | null>(null);
  const [updatedStatuses, setUpdatedStatuses] = useState<
    Record<string, string>
  >({});
  const [evaluators, setEvaluators]= useState<Evaluator[]>([]);
  const [evalErrorCode, setEvalErrorCode] = useState<number>(0);
  const activeTenantId = useAppSelector((state) => state.tenant.active);

  const handleError = (error: unknown, customMessage?: string) => {
    if (error instanceof AxiosError) {
      const errorMessage = error.response?.data?.error;
      toast.error(
        `${COMPONENT_PREFIX}: ${
          errorMessage ||
          customMessage ||
          "An unexpected network error occurred"
        }`
      );
    } else if (error instanceof Error) {
      toast.error(`${COMPONENT_PREFIX}: ${error.message}`);
    } else {
      setEvalErrorCode(500);
      toast.error(
        `${COMPONENT_PREFIX}: ${
          customMessage ?? "An unexpected error occurred"
        }`
      );
    }
  };

  const fetchEvaluators = async (): Promise<void> => {
    setEvalErrorCode(0);
    setEvaluators([]);
    try {
      const response = await backendAPI.get(
        "/project-management/tenantEvaluators?status=CREATED"
      );
      setEvalErrorCode(200)
      setEvaluators(response.data);
    } catch (error) {
          if (error instanceof AxiosError && error.response) {
          const errorCode = error.response.status;
          setEvalErrorCode(errorCode);
        } else {
          setEvalErrorCode(500);
        }
      }
  };

  useEffect(() => {
    fetchEvaluators();
  },[reloadPending, activeTenantId])

  const handleEvaluatorStatus = async (
    e: any,
    id: string
  ) => {
    const status = e.target.value;
    try {
      const response = await backendAPI.patch(
        `/project-management/evaluators/${id}/changeStatus`,
        { status }
      );

      if (response.status === 200) {
        setUpdatedStatuses((prev) => ({
          ...prev,
          [id]: status,
        }));

        const actionType = status === "APPROVED" ? "approved" : "rejected";
        toast.success(
          `${COMPONENT_PREFIX}: ${response.data.name} was successfully ${actionType}`
        );

        setConfirmationId(null);
        setPendingStatus(null);
      }
      setConfirmationId(null);
      setPendingStatus(null);
      reloadEval();
    } catch (error) {
      if (e.target) {
        e.target.value = "CREATED";
      }
      handleError(
        error,
        `Failed to ${status.toLowerCase()} the evaluator. Please try again.`
      );
    }
  };

  const columns = React.useMemo(
    () => [
      {
        header: "Name",
        accessor: "name" as const,
        render: (evaluator: Evaluator) => (
          <div className="truncate">{evaluator.name}</div>
        ),
      },
      {
        header: "Language",
        accessor: "language" as const,
        render: (evaluator: Evaluator) => (
          <div className="truncate">{formatLanguage(evaluator.language)}</div>
        ),
      },
      {
        header: "Date Created",
        accessor: "createdAt" as const,
        render: (evaluator: Evaluator) => (
          <div className="truncate">
            {getDateFromTimestamp(parseInt(evaluator.createdAt), true)}
          </div>
        ),
      },
      {
        header: "Actions",
        accessor: "actions" as const,
        render: (evaluator: Evaluator) => (
          <div className="flex items-center justify-end gap-2">
            <div className="w-[250px] flex justify-end">
              <div className="flex justify-end">
                <ActionButtons  evaluator={evaluator} updatedStatuses={updatedStatuses} confirmationId={confirmationId} pendingStatus={pendingStatus} handleEvaluatorStatus={handleEvaluatorStatus} setConfirmationId={setConfirmationId} setPendingStatus={setPendingStatus}/>
              </div>
            </div>
            <DownloadBtn
              item={evaluator}
              endpoint={`/project-management/evaluators/${evaluator.id}/download`}
              style="rounded-lg border shadow-md active:scale-95 text-xs w-fit hover:shadow-sm"
            />
          </div>
        ),
      },
    ],
    [confirmationId, pendingStatus, updatedStatuses]
  );

  return (
    <>
      <div className="mt-4">
        <h2 className="text-lg font-bold m-4">
          Pending Approval - {evaluators.length}
        </h2>
      </div>

      <div className="overflow-x-auto">
        <div className="table-fixed">
          <Table
            data={evaluators}
            columns={columns}
            className="w-full whitespace-nowrap"
          />
        </div>
      </div>

      <EmptyList 
        selector={evalErrorCode}
        logo={<ShieldAlert size={50} />} 
        title="All clear! No pending evaluators" 
        subtitle="—sit back and relax. 😊"
      />
    </>
  );
};

export default PendingEvaluator;

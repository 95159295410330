export const hardwaresList = [
	{
		label: "CPU - Nvidia GPU",
		value: "cognac",
		title: "Nvidia GPU with CUDA-Accelerated Parallel Processing.",
	},
	{
		label: "CPU Single Thread",
		value: "ale",
		title: "Evaluating the Problem Using Serial Algorithms.",
	},
	{
		label: "CPU Multi-Thread",
		value: "bourbon",
		title: "Evaluating the Problem Using Multi-Threaded Algorithms.",
	},
];

export const getHardware = (value: string) => {
	return hardwaresList.filter((hardware) => hardware.value === value)[0];
};
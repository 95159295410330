import React from "react";
import { BusinessRole } from "./types";

interface BusinessRolesListProps {
  businessRoles: BusinessRole[];
  selectedBusinessRole: BusinessRole | null;
  isLoading: boolean;
  searchBusinessTerm: string;
  setSearchBusinessTerm: (term: string) => void;
  onSelectRole: (role: BusinessRole) => void;
  onToggleCreateForm: () => void;
  showCreateForm: boolean;
  onDeleteRole: (role: BusinessRole) => void;
}

const BusinessRolesList: React.FC<BusinessRolesListProps> = ({
  businessRoles,
  selectedBusinessRole,
  isLoading,
  searchBusinessTerm,
  setSearchBusinessTerm,
  onSelectRole,
  onToggleCreateForm,
  showCreateForm,
  onDeleteRole,
}) => {
  return (
    <div className="bg-white p-5 rounded-lg shadow-sm border border-gray-200">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <svg
            className="w-5 h-5 text-blue-600 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
          <h2 className="text-lg font-semibold">Business Roles</h2>
        </div>
        <button
          type="button"
          onClick={onToggleCreateForm}
          className="text-blue-600 hover:text-blue-800 p-1 rounded-full"
          title="Add new role"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
        </button>
      </div>

      <div className="mb-3">
        <div className="relative">
          <input
            type="text"
            placeholder="Search roles..."
            value={searchBusinessTerm}
            onChange={(e) => setSearchBusinessTerm(e.target.value)}
            className="w-full pl-9 pr-3 py-2 border border-gray-300 rounded-md"
          />
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg
              className="h-4 w-4 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
        </div>
      </div>

      {businessRoles.length === 0 ? (
        <div className="bg-gray-50 p-4 rounded-md">
          <p className="text-gray-500 text-sm text-center">
            {isLoading ? "Loading roles..." : "No business roles available"}
          </p>
        </div>
      ) : (
        <div className="max-h-96 overflow-y-auto">
          <ul className="divide-y divide-gray-200">
            {businessRoles
              .filter((role) =>
                role.businessRole
                  .toLowerCase()
                  .includes(searchBusinessTerm.toLowerCase())
              )
              .map((role) => {
                const isSelected = selectedBusinessRole?.id === role.id;
                return (
                  <li
                    key={role.id}
                    className={`flex items-center justify-between p-3 transition-colors ${
                      isSelected
                        ? "bg-blue-50 text-blue-700 font-medium"
                        : "hover:bg-gray-50 text-gray-700"
                    }`}
                  >
                    <button
                      type="button"
                      onClick={() => onSelectRole(role)}
                      className="w-full text-left"
                      disabled={isLoading}
                    >
                      {role.businessRole}
                    </button>
                    <button
                      type="button"
                      onClick={() => onDeleteRole(role)}
                      disabled={isLoading}
                      title="Delete Role"
                      className={`flex items-center justify-center w-8 h-8 rounded-full ml-2 ${
                        isSelected
                          ? "bg-blue-50 text-blue-700"
                          : "bg-white text-red-500 hover:text-red-700"
                      }`}
                    >
                      <svg
                        className="w-4 h-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default BusinessRolesList;

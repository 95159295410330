import { Loader } from "lucide-react";
import React, { useEffect, useState } from "react";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
}) => {

  const [deleting, setDeleting] = useState<boolean>(false);
  const handleDeleteClick = () => {
    setDeleting(!deleting);
    onConfirm();
  }

  useEffect(() => {
    if (!isOpen) {
      setDeleting(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
        <h3 className="text-lg font-semibold mb-2">{title}</h3>
        <p className="text-gray-600 mb-6">{message}</p>
        <div className="flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={handleDeleteClick}
            className={` ${deleting && "opacity-70 pointer-events-none"} flex gap-2 items-center bg-red-500 px-5 pl-3 text-white rounded-md hover:bg-red-600`}
          >
            <div className={` transition-all ${deleting ? " w-5 " : " w-0 overflow-hidden"}`}>
							<Loader className="w-4 h-4 mr-2 animate-spin" />
						</div>
						<p className=" flex-1">Delete</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;

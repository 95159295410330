
const getStatusStyles = (status: string): string => {
	switch (status) {
		case "COMPLETED":
			return "bg-green-100 text-green-800 px-4";
		case "RUNNING":
			return "bg-blue-100 text-blue-800 px-4";
		case "CREATED":
			return "bg-blue-100 text-blue-800 px-4";
		case "FAILED":
			return "bg-red-100 text-red-800 px-4";
		case "DELETED":
			return "bg-red-100 text-red-800 px-4";
		default:
			return "";
	}
};

const Skelton = () => (
	<div className="animate-pulse w-full  flex flex-col gap-5 mt-4">
		<div className='relative flex justify-between'>
			<div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-44"></div>
			<div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-1/2"></div>
		</div>
		<div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48"></div>
		<div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-60 "></div>
		<div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-44 "></div>
	</div>
)

const Detail = ({ execution }: any) => {
	return (
		<div className="w-full">
			<h2 className={`text-xl font-semibold mb-1 ${!execution && " animate-pulse"}`}>Execution Details</h2>
			{execution ? <div className="p-2 space-y-2">
				<div className="flex items-center justify-between space-x-6">
					<div className="flex space-x-4 items-center">
						<span className="font-semibold text-gray-700">
							Project Name:
						</span>
						<span className="text-gray-600">{execution?.project?.name}</span>
					</div>
					<div className="flex space-x-4 items-center">
						<span className="font-semibold text-gray-700">Status:</span>
						<span
							className={`${getStatusStyles(execution.status)} p-1 rounded`}
						>
							{execution.status}
						</span>
					</div>
				</div>
				<div className="flex space-x-4 items-center">
					<span className="font-semibold text-gray-700">
						Execution Name:
					</span>
					<span className="text-gray-600">Execution {execution.id}</span>
				</div>

				<div className="flex space-x-4 items-center">
					<span className="font-semibold text-gray-700 whitespace-nowrap">
						Status Reason:
					</span>
					<span className="text-gray-600 break-words">
						{execution.statusReason}
					</span>
				</div>
				<div className="flex space-x-4 items-center">
					<span className="font-semibold text-gray-700">
						Server Name:
					</span>
					<span className="text-gray-600">{execution?.server?.serverName}</span>
				</div>
			</div>
				: <Skelton />
			}
		</div>

	)
}

export default Detail
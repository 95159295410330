import { configureStore } from "@reduxjs/toolkit";
import evaluatorReducer from "./redux/evaluatorSlice";
import executionReducer from "./redux/executionSlice";
import globalCheckSliceReducer from "./redux/globalCheckSlice";
import serverReducer from "./redux/serverSlice";
import tenantReducer from "./redux/tenantSlice";
import authReducer from './redux/authSlice'
export const store = configureStore({
  reducer: {
    tenant: tenantReducer,
    server: serverReducer,
    execution: executionReducer,
    evaluator: evaluatorReducer,
    globalCheck: globalCheckSliceReducer,
    auth: authReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

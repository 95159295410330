import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { EvaluatorPage } from "../pages/layout/EvaluatorPage";
import { ExecutionPage } from "../pages/layout/ExecutionPage";
import ManageAccess from "../pages/layout/ManageAccess";
import { ProjectPage } from "../pages/layout/ProjectPage";
import { ServerDetailsPage } from "../pages/layout/ServerDetailsPage";
import { ServerPage } from "../pages/layout/ServerPage";
import TermsAndConditions from "../pages/layout/TermsAndConditions";
import ProtectedRoute from "./ProtectedRoute";
import AccessDenied from "./AccessDenied";

// const AppRoutes: React.FC<{ auth: boolean }> = ({ auth }) => {
const AppRoutes: React.FC = ({auth}:any) => {
  return (
    <Routes>
      {auth && (
        <>
          <Route path="/evaluators" element={<EvaluatorPage />} />
          <Route path="/projects" element={<ProjectPage />} />
          <Route path="/servers" element={<ServerPage />} />

          <Route
            path="/manage-access"
            element={
              <ProtectedRoute>
                <ManageAccess />
              </ProtectedRoute>
            }
          />

          <Route path="/" element={<Navigate to="/projects" />} />
          <Route path="*" element={<Navigate to="/projects" />} />
          <Route path="/projects/:projectId/executions/:executionId" element={<ExecutionPage />} />
          <Route path="/servers/:serverId" element={<ServerDetailsPage />} />
        </>
      )}
      <Route path="/terms" element={<TermsAndConditions />} />
      <Route path="/access-denied" element={<AccessDenied />} />
    </Routes>
  );
};

export default AppRoutes;

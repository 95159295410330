import { useState } from 'react'
import { getErrorMessageStyles } from './ExecutionGraphPage';

const Skelton = () => <div className="animate-pulse rounded-lg w-96 opacity-80 flex flex-col gap-6 border border-gray-400 pb-3">
  <div className=' flex gap-4 border-b-2 py-4 items-center px-2'>
    <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-44"></div>
    <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-600 w-44"></div>
  </div>
  <div className=' flex gap-4 px-2'>
    <div className="h-3 bg-gray-300 rounded-full dark:bg-gray-700 w-36"></div>
    <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-600 w-44"></div>
  </div>
  <div className=' flex gap-4 px-2'>
    <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-36"></div>
    <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-600 w-full"></div>
  </div>
</div>

const DesignVariablesTable = ({selectedTrial, designVariableData}:{selectedTrial:number, designVariableData:any}) => {
  return (<div className="overflow-hidden rounded-lg border border-gray-300 w-fit">
    <table className="bg-white">
      <thead>
        <tr className="bg-gray-100 text-gray-500 border-b border-gray-300">
          <th className="px-6 py-3 font-medium text-left border-r border-gray-300">
            Design Variable
          </th>
          <th className="px-4 py-2 whitespace-nowrap">
            Best Design Variable Trial {selectedTrial}
          </th>
        </tr>
      </thead>
      <tbody>
        {designVariableData.map((row: any, index: number) => (
          <tr
            key={row["Design Variable"]}
            className={`${index !== designVariableData.length - 1
              ? "border-b border-gray-300"
              : ""
              }`}
          >
            <td className="px-4 py-2 whitespace-nowrap border-r border-gray-300">
              {row["Design Variable"]}
            </td>
            <td className="px-4 py-2 whitespace-nowrap">
              {row[`Best Design Variable Trial ${selectedTrial}`] || "N/A"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>)
}


const DesignVariables = ({ errorMessage, execution, designVariableData, numTrials }: any) => {
  const [selectedTrial, setSelectedTrial] = useState<number>(1);

  return (
    <div className=" w-full text-left">
      <h2 className={`text-xl font-semibold mb-4 ${!execution && " animate-pulse"}`}>
        Design Variables
      </h2>
      {execution ? execution.status === "COMPLETED" &&
        <div className="my-4 w-full text-left">
          <label htmlFor="trial" className="font-semibold">Select Trial: </label>
          <select
            id="trial"
            value={selectedTrial}
            onChange={(e) => setSelectedTrial(Number(e.target.value))}
            className={` ${!numTrials && " animate-pulse opacity-50"} border border-gray-300 p-2 pr-4 rounded ml-2`}
          >
            {numTrials ? Array.from({ length: numTrials }).map((_, trialIndex) => {
              const trial = trialIndex + 1;
              return (
                <option key={trial} value={trial}>
                  Trial {trial}
                </option>
              );
            })
              : <option>Loading...</option>
            }
          </select>
        </div>
        : <div className="max-w-sm animate-pulse flex gap-4">
          <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-16 mb-4"></div>
          <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-700 w-36 mb-4"></div>
        </div>
      }

      {errorMessage ?
        <div
          className={`font text-left py-2 tracking-wider rounded-md ${getErrorMessageStyles(
            execution?.status || ""
          )}`}
        >
          {errorMessage}
        </div>
        : 
        designVariableData ? <DesignVariablesTable selectedTrial={selectedTrial} designVariableData={designVariableData}/> : <Skelton /> 
      }

    </div>
  )
}

export default DesignVariables
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { toast, Toaster } from "sonner";
import backendAPI from "../../../services/apiRequestService";
import Table from "../../common/Table";
import ViewServerDetails from "./components/ViewServerDetails";
import ServerModal from "./ServerModal";
import { useAppSelector } from "../../../redux/hooks";
import ThreeDot from "../../common/ThreeDot";
import EmptyList from "../../common/EmptyList";
import { Server as ServerIcon } from "lucide-react";
import ConfirmationModal from "../../common/popup/ConfirmationModal";
import { getHardware } from "./components/HardwaresList";

export interface Server {
  id: string;
  host: string;
  port: number;
  userName: string;
  status: string;
  hardware: string;
  serverName: string;
}

const ServerTable: React.FC = () => {
  const [servers, setServers] = useState<Server[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedServer, setSelectedServer] = useState<Server | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [deletingServer, setDeletingServer] = useState<Server | null>(null);
  const [pageErrorCode, setPageErrorCode] = useState<number>(0);

  const activeTenantId = useAppSelector((state) => state.tenant.active);

  const menuState = {
    visible: { view: true, delete:true }
  };

  const handleAction = (action: any) => {
    switch(action.option){
      case "view" :
        handleView(action.element)
        break;
      case "delete" :
        handleDeleteClick(action.element)
        break;
      default:
        toast.info(`${action.option}: Option not available`)
    }

  };

  const fetchServers = async (): Promise<void> => {
    setPageErrorCode(0);
    setServers([]);
    try {
      const response = await backendAPI.get("/project-management/servers");
      setServers(response.data);
      setPageErrorCode(200);
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorCode = error.response.status;
        setPageErrorCode(errorCode);
      } else {
        setPageErrorCode(500);
      }
    }
  };

  useEffect(() => {
    fetchServers();
  }, [activeTenantId]);

  const handleAddServer = (): void => {
    setShowModal(true);
  };

  const onRegister = async (): Promise<void> => {
    setShowModal(false);
    fetchServers();
  };

  const handleView = (server: Server): void => {
    setSelectedServer(server);
    setDropdownOpen(null);
  };

  const handleDeleteClick = (server: Server): void => {
    setDeletingServer(server);
    setShowConfirmation(true);
  };

  const handleDelete = async (): Promise<void> => {
    if (!deletingServer) return;
    try {
      await backendAPI.delete(
        `/project-management/servers/${deletingServer.id}`
      );
      setServers((prevServers) =>
        prevServers.filter((server) => server.id !== deletingServer.id)
      );
      toast.success("Server deleted successfully.");
      fetchServers();
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        toast.error(
          error.response?.data?.message || "Error deleting the Server"
        );
      } else {
        toast.error("An unknown error occurred while deleting the server.");
      }
    } finally {
      setShowConfirmation(false);
      setDeletingServer(null);
    }
  };

  const handleCancelDelete = (): void => {
    setShowConfirmation(false);
    setDeletingServer(null);
  };

  const columns = React.useMemo(
    () => [
      {
        header: "ServerName",
        accessor: "serverName" as const,
      },
      {
        header: "Host",
        accessor: "host" as const,
      },
      {
        header: "Port",
        accessor: "port" as const,
      },
      {
        header: "Username",
        accessor: "userName" as const,
      },
      {
        header: "Hardware",
        accessor: "hardware" as const,
        render: (server: Server) => {
          const hardware =  getHardware(server.hardware);
          return hardware ? <p title={hardware.title}>{hardware.label}</p> : <p>Unknown</p>
        },
      },
      {
        header: "Actions",
        accessor: "actions" as const,
        render: (server: Server, index: number) => (
          <div className=" flex justify-end items-center">
            <ThreeDot
              visible={menuState.visible}
              onAction={handleAction}
              on={server}
              isOpen={dropdownOpen === index}
              toggleDropdown={(event:any) => {
                if (event === null) {
                  setDropdownOpen(null);
                  return;
                }
                event.stopPropagation();
                setDropdownOpen(dropdownOpen === index ? null : index);
              }}
            />
          </div>
        ),
      },
    ],
    [dropdownOpen]
  );

  return (<>
    <div className="pt-4 shadow-inner">
      <Toaster richColors={true}/>
      <div className="flex justify-between items-center p-4">
        <h2 className="text-lg font-bold">
          Total Servers - {servers.length}
        </h2>
        <button
          className="bg-black text-white px-4 py-2 rounded-md flex items-center"
          onClick={handleAddServer}
        >
          + Add Server
        </button>
      </div>

      <Table
        data={servers}
        columns={columns}
        onRowClick={() => {
          setDropdownOpen(null);
        }}
        striped={true}
        className="w-full"
      />

      <EmptyList
        selector = {pageErrorCode}
        logo = {<ServerIcon size={50} className="text-gray-800" />}
        title = "No Servers Added Yet"
        subtitle = "There are no servers associated with your account. Add "
        showSupportMail = {false}
        button = "Add Your Server"
        onClick={handleAddServer}
      />

      {selectedServer && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <ViewServerDetails
            server={selectedServer}
            onClose={() => setSelectedServer(null)}
          />
        </div>
      )}
      </div>

      {showModal && <ServerModal onClose={() => setShowModal(false)} onRegister={onRegister} />}

      <ConfirmationModal
        isOpen={showConfirmation}
        onClose={handleCancelDelete}
        onConfirm={handleDelete}
        title="Delete Server"
        message="Are you sure you want to delete this server? This action cannot be undone."
      />
    </>
  );
};

export default ServerTable;

import React from "react";
import { BusinessRole } from "./types";

interface DeleteConfirmationModalProps {
  show: boolean;
  selectedBusinessRole: BusinessRole;
  isLoading: boolean;
  onCancel: () => void;
  onDelete: () => void;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  show,
  selectedBusinessRole,
  isLoading,
  onCancel,
  onDelete,
}) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md mx-4">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Delete Role</h3>
        <p className="text-gray-500 mb-4">
          Are you sure you want to delete the role{" "}
          {selectedBusinessRole.businessRole}? This action cannot be undone.
        </p>
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={onDelete}
            className="px-4 py-2 bg-red-600 border border-transparent rounded-md text-white hover:bg-red-700 disabled:bg-red-300"
            disabled={isLoading}
          >
            {isLoading ? "Deleting..." : "Delete"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;

import { Loader, PackagePlus } from 'lucide-react'

const AddProject = ({isSubmitting}:{isSubmitting:boolean}) => {
	return (
		<div className="pt-2 mt-2 border-t flex justify-end">
			<button
				type="submit"
				disabled={isSubmitting}
				className={` ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""} px-3 items-center py-1.5 flex gap-3 rounded-md bg-teal-600 text-white hover:bg-teal-700`}
			>
				{isSubmitting ? (
					<Loader size={20} className="animate-spin" />
				) : (
					<PackagePlus size={20} />
				)}
				<p>Add Project</p>
			</button>
		</div>
	)
}

export default AddProject
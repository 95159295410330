import React, { useState, useEffect } from "react";
import { PolicyRule } from "./types";

interface CreateRoleFormProps {
  newRoleName: string;
  setNewRoleName: (name: string) => void;
  createRoleError: string | null;
  selectedFunctionalRoles: string[];
  setSelectedFunctionalRoles: (roles: string[]) => void;
  searchRoleTerm: string;
  setSearchRoleTerm: (term: string) => void;
  availableFunctionalRoles: PolicyRule[];
  isLoading: boolean;
  onCreateRole: () => void;
  onCancel: () => void;
}

const CreateRoleForm: React.FC<CreateRoleFormProps> = ({
  newRoleName,
  setNewRoleName,
  createRoleError,
  selectedFunctionalRoles,
  setSelectedFunctionalRoles,
  searchRoleTerm,
  setSearchRoleTerm,
  availableFunctionalRoles,
  isLoading,
  onCreateRole,
  onCancel,
}) => {
  const filteredFunctionalRoles = availableFunctionalRoles.filter(
    (role) =>
      role.name.toLowerCase().includes(searchRoleTerm.toLowerCase()) ||
      (role.description &&
        role.description.toLowerCase().includes(searchRoleTerm.toLowerCase()))
  );

  const allFilteredSelected =
    filteredFunctionalRoles.length > 0 &&
    filteredFunctionalRoles.every((role) =>
      selectedFunctionalRoles.includes(role.id)
    );

  const handleToggleSelectAll = () => {
    if (allFilteredSelected) {
      const remaining = selectedFunctionalRoles.filter(
        (id) => !filteredFunctionalRoles.find((role) => role.id === id)
      );
      setSelectedFunctionalRoles(remaining);
    } else {
      const newSelected = [
        ...new Set([
          ...selectedFunctionalRoles,
          ...filteredFunctionalRoles.map((role) => role.id),
        ]),
      ];
      setSelectedFunctionalRoles(newSelected);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold">Create New Role</h2>
        <div className="flex items-center space-x-2">
          <button
            type="button"
            onClick={onCancel}
            className="text-gray-500 hover:text-gray-700"
            title="Cancel and close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <button
            type="button"
            onClick={onCreateRole}
            className="bg-blue-600 text-white px-4 py-1 rounded-md hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
            disabled={isLoading || !newRoleName.trim()}
          >
            {isLoading ? "Creating..." : "Create Role"}
          </button>
        </div>
      </div>

      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Business Role Name
        </label>
        <input
          type="text"
          value={newRoleName}
          onChange={(e) => setNewRoleName(e.target.value)}
          placeholder="Enter role name"
          className="w-full px-4 py-2 border border-gray-300 rounded-md"
          disabled={isLoading}
        />
        {createRoleError && (
          <p className="text-red-500 text-xs mt-1">{createRoleError}</p>
        )}
      </div>

      <div className="mb-6">
        <div className="flex justify-between items-center mb-3">
          <h3 className="text-md font-medium text-gray-700">
            Select Functional Roles
          </h3>
          <span className="text-sm bg-blue-100 text-blue-800 px-2 py-1 rounded-full">
            {selectedFunctionalRoles.length} selected
          </span>
        </div>

        {availableFunctionalRoles.length > 9 && (
          <div className="mb-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search for functional roles..."
                value={searchRoleTerm}
                onChange={(e) => setSearchRoleTerm(e.target.value)}
                className="w-full pl-9 pr-3 py-2 border border-gray-300 rounded-md"
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg
                  className="h-4 w-4 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        )}

        {selectedFunctionalRoles.length > 0 && (
          <div className="mb-4">
            <div className="flex flex-wrap gap-2">
              {selectedFunctionalRoles.map((roleId) => {
                const roleInfo = availableFunctionalRoles.find(
                  (r) => r.id === roleId
                );
                return (
                  <div
                    key={`selected-${roleId}`}
                    className="inline-flex items-center bg-blue-50 text-blue-700 px-3 py-1 rounded-full text-sm"
                  >
                    <span>{roleInfo?.name || roleId}</span>
                    <button
                      type="button"
                      onClick={() =>
                        setSelectedFunctionalRoles(
                          selectedFunctionalRoles.filter((id) => id !== roleId)
                        )
                      }
                      className="ml-1 text-blue-500 hover:text-blue-700"
                    >
                      <svg
                        className="w-4 h-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {availableFunctionalRoles.length === 0 ? (
          <div className="bg-gray-50 p-4 rounded-md">
            <p className="text-gray-500 italic text-sm text-center">
              {isLoading
                ? "Loading functional roles..."
                : "No functional roles available"}
            </p>
          </div>
        ) : filteredFunctionalRoles.length === 0 ? (
          <div className="bg-gray-50 p-4 rounded-md">
            <p className="text-gray-500 italic text-sm text-center">
              No matching functional roles found
            </p>
          </div>
        ) : (
          <>
            {/* Select All / Deselect All Button */}
            <div className="mb-4 text-right">
              <button
                type="button"
                onClick={handleToggleSelectAll}
                className="text-sm text-blue-600 hover:underline"
              >
                {allFilteredSelected ? "Deselect All" : "Select All"}
              </button>
            </div>

            <div className="border border-gray-200 rounded-md bg-white">
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-0">
                {filteredFunctionalRoles.map((role) => {
                  const isSelected = selectedFunctionalRoles.includes(role.id);
                  return (
                    <div
                      key={role.id}
                      onClick={() => {
                        if (isSelected) {
                          setSelectedFunctionalRoles(
                            selectedFunctionalRoles.filter(
                              (id) => id !== role.id
                            )
                          );
                        } else {
                          setSelectedFunctionalRoles([
                            ...selectedFunctionalRoles,
                            role.id,
                          ]);
                        }
                      }}
                      className={`cursor-pointer p-3 border border-gray-100 group relative transition-colors ${
                        isSelected
                          ? "bg-blue-50 border-blue-200"
                          : "hover:bg-gray-50"
                      }`}
                    >
                      <div className="flex items-center">
                        <div
                          className={`flex-shrink-0 w-4 h-4 mr-2 rounded border ${
                            isSelected
                              ? "bg-blue-600 border-blue-600"
                              : "border-gray-300"
                          }`}
                        >
                          {isSelected && (
                            <svg
                              className="w-4 h-4 text-white"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          )}
                        </div>
                        <span
                          className={`text-sm ${
                            isSelected
                              ? "font-medium text-blue-700"
                              : "text-gray-700"
                          }`}
                          title={role.description || ""}
                        >
                          {role.name}
                        </span>
                      </div>
                      {role.description && (
                        <div className="hidden group-hover:block absolute z-10 top-full left-0 mt-1 w-64 p-2 bg-gray-800 text-white text-xs rounded shadow-lg">
                          {role.description}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateRoleForm;

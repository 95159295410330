import { useState } from "react";
import Header from "../../components/common/Header";
import Sidebar from "../../components/common/Sidebar";
import ExecutionGraphPage from "../../components/layout/projects/executionDetails/ExecutionGraphPage";
export interface SidebarProps {
  isOpen: boolean;
}

export interface HeaderProps {
  onToggleSidebar: () => void;
}

export const ExecutionPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex bg-gray-50">
      <Sidebar isOpen={isSidebarOpen} />

      <div className="flex-1 flex flex-col">
        <Header onToggleSidebar={handleToggleSidebar} />
         <ExecutionGraphPage />
      </div>
    </div>
  );
};

export default ExecutionPage;

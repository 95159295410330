import React, { useState, useEffect } from "react";
import Header from "../../components/common/Header";
import Sidebar from "../../components/common/Sidebar";
import { toast, Toaster } from "sonner";
import { useAppSelector } from "../../redux/hooks";
import BusinessRolesList from "../../components/layout/manageAccess/BusinessRolesList";
import CreateRoleForm from "../../components/layout/manageAccess/CreateRoleForm";
import RoleDetailsDisplay from "../../components/layout/manageAccess/RoleDetailsDisplay";
import DeleteConfirmationModal from "../../components/layout/manageAccess/DeleteConfirmationModal";
import {
  BusinessRole,
  RoleDetail,
  PolicyRule,
} from "../../components/layout/manageAccess/types";
import backendAPI from "../../services/apiRequestService";

const ManageAccess: React.FC = () => {
  const activeTenantId = useAppSelector((state) => state.tenant.active);

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [businessRoles, setBusinessRoles] = useState<BusinessRole[]>([]);
  const [selectedBusinessRole, setSelectedBusinessRole] =
    useState<BusinessRole | null>(null);
  const [roleDetails, setRoleDetails] = useState<RoleDetail | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [availableFunctionalRoles, setAvailableFunctionalRoles] = useState<
    PolicyRule[]
  >([]);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newRoleName, setNewRoleName] = useState("");
  const [selectedFunctionalRoles, setSelectedFunctionalRoles] = useState<
    string[]
  >([]);
  const [createRoleError, setCreateRoleError] = useState<string | null>(null);
  const [searchRoleTerm, setSearchRoleTerm] = useState("");
  const [searchBusinessTerm, setSearchBusinessTerm] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [notAuthorized, setNotAuthorized] = useState(false);

  const createHeaders = () => {
    const token = localStorage.getItem("token");
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      tenantid: activeTenantId || "",
    };
  };

  const handleApiError = (error: any, fallbackMessage: string) => {
    console.error("API Error:", error);
    let errorMessage = fallbackMessage;
    if (error.response) {
      errorMessage = error.response.data?.message || fallbackMessage;
    }
    setError(errorMessage);
    toast.error(errorMessage);
  };

  const handleToggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };


  const resetCreateForm = () => {
    setNewRoleName("");
    setSelectedFunctionalRoles([]);
    setCreateRoleError(null);
    setSearchRoleTerm("");
  };


  const toggleCreateForm = () => {
    if (showCreateForm) {
      resetCreateForm();
      setShowCreateForm(false);
    } else {
      setShowCreateForm(true);
      setSelectedBusinessRole(null);
      setRoleDetails(null);
    }
  };


  const handleBusinessRoleSelect = (role: BusinessRole) => {
    if (showCreateForm) {
      setShowCreateForm(false);
      resetCreateForm();
    }
    setSelectedBusinessRole(role);
  };


  const fetchBusinessRoles = async () => {
    try {
      setIsLoading(true);
      setNotAuthorized(false);

      const response = await backendAPI.get("/access-control/roles", {
        headers: createHeaders(),
      });

      if (Array.isArray(response.data)) {
        if (!response.data.includes("USER_ADMIN")) {
          setNotAuthorized(true);
          setBusinessRoles([]);
        } else {
          const formatted = response.data.map((roleName: string) => ({
            id: roleName,
            businessRole: roleName,
          }));
          setBusinessRoles(formatted);
        }
      } else {
        setBusinessRoles([]);
      }

      setIsLoading(false);
    } catch (err) {
      setBusinessRoles([]);
      setIsLoading(false);
    }
  };

  const fetchAvailableFunctionalRoles = async () => {
    try {
      setIsLoading(true);
      const response = await backendAPI.get("/access-control/policy-rules", {
        headers: createHeaders(),
      });
      let policyRules = [];
      if (response.data.rules && Array.isArray(response.data.rules)) {
        policyRules = response.data.rules;
      } else if (Array.isArray(response.data)) {
        policyRules = response.data;
      }
      const uniqueMap = new Map();
      policyRules.forEach((rule: any) => {
        const roleKey = rule.role;
        if (!uniqueMap.has(roleKey)) {
          uniqueMap.set(roleKey, {
            id: roleKey,
            name: roleKey,
            description: rule.description || "",
          });
        }
      });
      setAvailableFunctionalRoles(Array.from(uniqueMap.values()));
      setIsLoading(false);
    } catch (err) {
      console.error("Error fetching functional roles", err);
      setAvailableFunctionalRoles([]);
      setIsLoading(false);
    }
  };

  const fetchRoleDetails = async (roleName: string) => {
    try {
      setIsLoading(true);
      const response = await backendAPI.get(
        `/access-control/roles/${roleName}`,
        {
          headers: createHeaders(),
        }
      );
      setRoleDetails({
        businessRole: roleName,
        functionalRoles: response.data?.functionalRoles || [],
      });
      setIsLoading(false);
    } catch (err) {
      console.error("Error fetching role details", err);
      setRoleDetails({ businessRole: roleName, functionalRoles: [] });
      setIsLoading(false);
    }
  };

  const handleCreateRole = async () => {
    if (!newRoleName.trim()) {
      setCreateRoleError("Role name is required");
      return;
    }
    if (
      businessRoles.some(
        (role) => role.businessRole.toLowerCase() === newRoleName.toLowerCase()
      )
    ) {
      setCreateRoleError("A role with this name already exists");
      return;
    }
    try {
      setIsLoading(true);
      const payload = {
        businessRole: newRoleName,
        functionalRoles: selectedFunctionalRoles,
      };
      await backendAPI.post("/access-control/roles", payload, {
        headers: createHeaders(),
      });
      await fetchBusinessRoles();
      resetCreateForm();
      setShowCreateForm(false);
      setIsLoading(false);
      toast.success("Business role created successfully");
    } catch (err) {
      handleApiError(err, "Failed to create role");
      setIsLoading(false);
    }
  };

  const handleUpdateRole = async (roleData: {
    businessRole: string;
    functionalRoles: string[];
  }) => {
    try {
      setIsLoading(true);
      await backendAPI.put("/access-control/roles", roleData, {
        headers: createHeaders(),
      });
      setRoleDetails({
        businessRole: roleData.businessRole,
        functionalRoles: roleData.functionalRoles,
      });
      setIsLoading(false);
      toast.success("Role updated successfully");
    } catch (err) {
      handleApiError(err, "Failed to update role");
      setIsLoading(false);
    }
  };


  const handleDeleteRole = async () => {
    if (!selectedBusinessRole) return;
    try {
      setIsLoading(true);
      await backendAPI.delete(
        `/access-control/roles/${selectedBusinessRole.id}`,
        { headers: createHeaders() }
      );
      await fetchBusinessRoles();
      setSelectedBusinessRole(null);
      setRoleDetails(null);
      setShowDeleteConfirm(false);
      setIsLoading(false);
      toast.success("Role deleted successfully");
    } catch (err) {
      handleApiError(err, "Failed to delete role");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (activeTenantId) {
      fetchBusinessRoles();
      fetchAvailableFunctionalRoles();
    }
  }, [activeTenantId]);

  useEffect(() => {
    if (selectedBusinessRole) {
      fetchRoleDetails(selectedBusinessRole.businessRole);
    }
  }, [selectedBusinessRole]);

  const handleRoleDetailsUpdate = (finalRoles: string[]) => {
    if (!selectedBusinessRole) return;
    handleUpdateRole({
      businessRole: selectedBusinessRole.businessRole,
      functionalRoles: finalRoles,
    });
  };

  if (notAuthorized) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-50 p-4">
        <h1 className="text-3xl font-bold text-red-600 mb-4">Access Denied</h1>
        <p className="text-lg text-gray-700 mb-6 text-center">
          You do not have the required permissions to view the Access Control
          section. Please contact your administrator if you believe this is an
          error.
        </p>
        <a
          href="/"
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Return to Dashboard
        </a>
      </div>
    );
  }

  return (
    <div className="flex">
      <Toaster position="top-right" />
      <Sidebar isOpen={isSidebarOpen} />
      <div className="flex-1 flex flex-col">
        <Header onToggleSidebar={handleToggleSidebar} />
        <div className="p-6">
          <h1 className="text-2xl font-bold mb-6">Access Control Management</h1>

          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
              {error}
              <button
                className="ml-2 text-red-700 hover:text-red-900 underline"
                onClick={() => {
                  setError(null);
                  fetchBusinessRoles();
                  fetchAvailableFunctionalRoles();
                }}
              >
                Retry
              </button>
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
            <div className="md:col-span-3">
              <BusinessRolesList
                businessRoles={businessRoles}
                selectedBusinessRole={selectedBusinessRole}
                isLoading={isLoading}
                searchBusinessTerm={searchBusinessTerm}
                setSearchBusinessTerm={setSearchBusinessTerm}
                onSelectRole={handleBusinessRoleSelect}
                onToggleCreateForm={toggleCreateForm}
                showCreateForm={showCreateForm}
                onDeleteRole={(role) => {
                  setSelectedBusinessRole(role);
                  setShowDeleteConfirm(true);
                }}
              />
            </div>

            <div className="md:col-span-9">
              {showCreateForm ? (
                <CreateRoleForm
                  newRoleName={newRoleName}
                  setNewRoleName={setNewRoleName}
                  createRoleError={createRoleError}
                  selectedFunctionalRoles={selectedFunctionalRoles}
                  setSelectedFunctionalRoles={setSelectedFunctionalRoles}
                  searchRoleTerm={searchRoleTerm}
                  setSearchRoleTerm={setSearchRoleTerm}
                  availableFunctionalRoles={availableFunctionalRoles}
                  isLoading={isLoading}
                  onCreateRole={handleCreateRole}
                  onCancel={toggleCreateForm}
                />
              ) : selectedBusinessRole ? (
                <RoleDetailsDisplay
                  selectedBusinessRole={selectedBusinessRole}
                  roleDetails={roleDetails}
                  availableFunctionalRoles={availableFunctionalRoles}
                  isLoading={isLoading}
                  searchRoleTerm={searchRoleTerm}
                  setSearchRoleTerm={setSearchRoleTerm}
                  onUpdateRole={handleRoleDetailsUpdate}
                  onDeleteRole={() => setShowDeleteConfirm(true)}
                />
              ) : (
                <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200 flex items-center justify-center h-64">
                  <div className="text-center">
                    <svg
                      className="w-12 h-12 text-gray-300 mx-auto mb-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 012 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                      />
                    </svg>
                    <h3 className="text-lg font-medium text-gray-500">
                      Select a role or create a new one
                    </h3>
                    <p className="text-gray-400 mt-1">
                      Choose a business role to view and manage its permissions
                    </p>
                    <button
                      type="button"
                      onClick={toggleCreateForm}
                      className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200"
                    >
                      <svg
                        className="w-4 h-4 mr-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                      </svg>
                      Create New Role
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showDeleteConfirm && selectedBusinessRole && (
        <DeleteConfirmationModal
          show={showDeleteConfirm}
          selectedBusinessRole={selectedBusinessRole}
          isLoading={isLoading}
          onCancel={() => setShowDeleteConfirm(false)}
          onDelete={handleDeleteRole}
        />
      )}
    </div>
  );
};

export default ManageAccess;

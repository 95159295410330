import { useState } from "react";
import Header from "../../components/common/Header";
import Notes from "../../components/common/Notes";
import Sidebar from "../../components/common/Sidebar";

export interface SidebarProps {
  isOpen: boolean;
}

export interface HeaderProps {
  onToggleSidebar: () => void;
}

export const ServerDetailsPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex">
      <Sidebar isOpen={isSidebarOpen} />

      <div className="flex-1 flex flex-col">
        <Header onToggleSidebar={handleToggleSidebar} />
         {/* < /> */}
      </div>

      <Notes height="h-[150vh]" />
    </div>
  );
};

export default ServerDetailsPage;

import React from "react";
import { Navigate } from "react-router-dom";
import { isUserAdmin } from "../services/apiRequestService";

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const adminStatus = isUserAdmin();

  if (!adminStatus) {
    return <Navigate to="/access-denied" replace />;
  }
  return children;
};

export default ProtectedRoute;

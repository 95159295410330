import { Evaluator, ProjectData } from "../../../../types/project"

interface EvaluatorsListProps {
	isLoading: boolean,
	error: string | null,
	evaluators: Evaluator[],
	projectData: ProjectData,
	handleEvaluatorSelect: (arg0: Evaluator) => void
}

const EvaluatorsList = ({ isLoading, error, evaluators, projectData, handleEvaluatorSelect }: EvaluatorsListProps) => {
	return (<>{ projectData.projectType === "GENERAL_OPTIMIZATION" &&
		<div>
			<span className="block mb-2 text-sm font-medium">
				Choose your Evaluator
			</span>
			{isLoading && <p>Loading evaluators...</p>}
			{error ? <p className="text-red-500">{error}</p> :
				<div className="space-y-2 max-h-44 overflow-y-auto shadow-inner">
					{evaluators.map((evaluator) => (
						<button
							type="button"
							key={evaluator.id}
							className={`w-full p-4 border rounded-md cursor-pointer ${projectData.evaluatorId === evaluator.id
								? "border-blue-500 bg-teal-50"
								: "border-gray-200 hover:border-blue-500"
								}`}
							onClick={() => handleEvaluatorSelect(evaluator)}
						>
							<div className="flex justify-between items-center">
								<span className=" text-md">{evaluator.name}</span>
								<div className="text-sm text-gray-500">
									<span>Language: {evaluator.language}</span>
									<span className="ml-4">{evaluator.date}</span>
								</div>
							</div>
						</button>
					))}
				</div>
			}
		</div>
	}
	</>
	)
}

export default EvaluatorsList
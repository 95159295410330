import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ServerState {
  ServerId: string | null;
}

const initialState: ServerState = {
  ServerId: null,
};

const serverSlice = createSlice({
  name: 'server',
  initialState,
  reducers: {
    setServerId(state, action: PayloadAction<string | null>) {
      state.ServerId = action.payload;
    },
    resetServerId(state) {
      state.ServerId = null;
    },
  },
});

export const { setServerId, resetServerId } = serverSlice.actions;
export default serverSlice.reducer;

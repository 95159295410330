import { X } from "lucide-react";
import React, { useRef, useState } from "react";
import { toast, Toaster } from "sonner";
import backendAPI from "../../../services/apiRequestService";
import { Evaluator, ProjectData } from "../../../types/project";
import MultiFileUpload from "../../common/MultiFileUpload";
import AddProject from "./components/AddProject";
import EvaluatorsList from "./components/EvaluatorsList";
import SimulationTypeSelector from "./components/SimulationTypeSelector";
import TreeStructure from "./components/TreeStructure";
import {
  ExtendedProjectData,
  ProjectType,
  ProjectTypeValue,
  TreeNodesType,
} from "./types";

interface ProjectModalProps extends React.PropsWithChildren {
  onClose: () => void;
  onAdd: (project: ProjectData & { dateCreated: string }) => void;
}
export const ProjectModal = React.forwardRef<HTMLDivElement, ProjectModalProps>(
  ({ onClose, onAdd }, ref) => {
    const [projectData, setProjectData] = useState<ExtendedProjectData>({
      id: "",
      name: "",
      projectType: "",
      status: "",
      evaluatorId: "",
      evaluatorName: "",
      evaluatorFunctionName: "",
      evaluatorLanguage: "",
      evaluatorFileName: "",
      evaluatorRequireFile: false,
      activeNode: "",
      numberOfTargets: undefined,
      manualValues: {
        coverageRatio: "",
        target: "",
        satellite: "",
      },
      isManualMode: false,
    });
    const [evaluators, setEvaluators] = useState<Evaluator[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [file, setFile] = useState<any>(null);
    const [collapsedNodes, setCollapsedNodes] = useState<
      Record<string, boolean>
    >({});
    const [validationErrors, setValidationErrors] = useState<{
      name: boolean;
    }>({
      name: false,
    });

    const fieldContainer = useRef<any>();

    const treeNodes: TreeNodesType = {
      GENERAL_OPTIMIZATION: [],
      SATELLITE_CONSTELLATION_OPTIMIZATION: [
        "CONSTELLATION",
        "SCHEDULING (coming soon)",
      ],
      SUPPLY_CHAIN_OPTIMIZATION: ["LOGISTICS", "WAREHOUSE"],
    };

    const fetchEvaluators = async (): Promise<void> => {
      setIsLoading(true);
      setError(null);
      backendAPI
        .get("/project-management/evaluators?status=APPROVED")
        .then((response) => {
          const formattedEvaluators = response.data.map((item: any) => ({
            id: item.id,
            name: item.name,
            language: item.language,
            requireFile: item.requireFile,
            date: new Date(item.createdAt).toLocaleDateString("en-US", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }),
          }));
          setEvaluators(formattedEvaluators);
        })
        .catch((error) => {
          toast.error(error.message);
          error.status === 404
            ? setError("No Approved Evaluators Available")
            : setError(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    const handleSimulationTypeSelect = (typeName: string): void => {
      const typeNameAsProjectType = typeName as ProjectTypeValue;

      const newProjectData: ExtendedProjectData = {
        ...projectData,
        projectType: typeNameAsProjectType,
        evaluatorId: "",
      };

      const projectTypeKey = typeName as ProjectType;

      if (
        projectTypeKey !== "GENERAL_OPTIMIZATION" &&
        treeNodes[projectTypeKey]?.length > 0
      ) {
        const firstNode = treeNodes[projectTypeKey][0];
        if (!firstNode.includes("coming soon")) {
          newProjectData.activeNode = firstNode;
          setCollapsedNodes((prev) => ({
            ...prev,
            [firstNode]: false,
          }));
        }
      } else {
        newProjectData.activeNode = "";
      }

      setProjectData(newProjectData);

      if (projectTypeKey === "GENERAL_OPTIMIZATION") {
        fetchEvaluators();
      }
    };

    const toggleNode = (node: string) => {
      if (projectData.activeNode === node) {
        setCollapsedNodes((prev) => ({
          ...prev,
          [node]: !prev[node],
        }));
      } else {
        setProjectData((prev) => ({ ...prev, activeNode: node }));
        setCollapsedNodes((prev) => ({
          ...prev,
          [node]: false,
        }));
      }
    };

    const handleEvaluatorSelect = (evaluator: Evaluator): void => {
      setProjectData({
        ...projectData,
        evaluatorId: evaluator.id,
        evaluatorRequireFile: evaluator.requireFile,
      });
    };

    const submitProject = async () => {
      setIsSubmitting(true);
      try {
        const data = {
          name: projectData.name,
          projectType: {
            id: projectData.projectType,
          },
          evaluator: {
            id: projectData.evaluatorId,
          },
        };

        if (
          projectData.projectType === "SATELLITE_CONSTELLATION_OPTIMIZATION" &&
          projectData.activeNode === "CONSTELLATION" &&
          projectData.numberOfTargets !== undefined
        ) {
          Object.assign(data, {
            numberOfTargets: projectData.numberOfTargets,
          });

          if (projectData.isManualMode) {
            Object.assign(data, {
              coverageRatio: projectData.manualValues?.coverageRatio,
              target: projectData.manualValues?.target,
              satellite: projectData.manualValues?.satellite,
            });
          }
        }

        const response = await backendAPI.post(
          "/project-management/projects",
          data
        );
        let newProject = response.data;

        const selectedEvaluator = evaluators.find(
          (e) => e.id === projectData.evaluatorId
        );

        if (
          file &&
          !projectData.isManualMode &&
          newProject.status === "UPLOAD_FILE"
        ) {
          const formData = new FormData();
          const filesArray = Array.isArray(file) ? file : [file];

          filesArray.forEach((file) => {
            formData.append('files', file);
          });

          let payloadTag = "GENERAL_OPT_PAYLOAD";
          const projectTypeKey = projectData.projectType as ProjectType;

          if (projectTypeKey === "SATELLITE_CONSTELLATION_OPTIMIZATION") {
            payloadTag = "SATELLITE_CONSTELLATION_PAYLOAD";
          } else if (projectTypeKey === "SUPPLY_CHAIN_OPTIMIZATION") {
            if (projectData.activeNode === "LOGISTICS") {
              payloadTag = "SC_LOGISTICS_NETWORK_PAYLOAD";
            } else if (projectData.activeNode === "WAREHOUSE") {
              payloadTag = "SUPPLY_CHAIN_WAREHOUSE_PAYLOAD";
            } else if (projectData.activeNode === "DEMAND") {
              payloadTag = "SUPPLY_CHAIN_DEMAND_PAYLOAD";
            } else {
              payloadTag = "SUPPLY_CHAIN_PAYLOAD";
            }
          }

          filesArray.forEach((f, index) => {
            if (index > 0)
              formData.append("SC_LOGISTICS_COSTVECTOR_PAYLOAD", f.name);
            else {
              formData.append(payloadTag, f.name);
            }
          });

          const fileResponse = await backendAPI.post(
            `/project-management/projects/${newProject.id}`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          );

          newProject = fileResponse.data;
          toast.success("File Uploaded.");
        }

        toast.success("Project Created Successfully!");
        onAdd({
          id: newProject.id,
          name: newProject.name,
          projectType: newProject.simulationType,
          status: newProject.status,
          evaluatorId: projectData.evaluatorId,
          evaluatorName: selectedEvaluator?.name,
          evaluatorFunctionName: newProject.evaluator?.functionName,
          evaluatorRequireFile: projectData.evaluatorRequireFile,
          evaluatorLanguage: selectedEvaluator?.language,
          evaluatorFileName: newProject.evaluator?.fileName,
          dateCreated: new Date(newProject.createdAt).toLocaleDateString(
            "en-US",
            {
              day: "2-digit",
              month: "short",
              year: "numeric",
            }
          ),
        });
      } catch (error: any) {
        toast.error(
          error.response?.data?.details ||
          error.response?.data?.message ||
          "Please check the fields."
        );
        setError("Failed to create project");
      } finally {
        onClose();
        setIsSubmitting(false);
      }
    };

    const validateForm = () => {
      setValidationErrors({ name: false });

      let isValid = true;
      if (projectData.name.trim() === "") {
        setValidationErrors((prev) => ({ ...prev, name: true }));
        isValid = false;
      }

      return isValid;
    };

    const createProject = async (): Promise<void> => {
      if (!validateForm()) {
        toast.error("Please provide a project name.");
        if (fieldContainer.current) {
          fieldContainer.current.scrollTop = 0;
        }
        return;
      }

      if (
        projectData.projectType === "" ||
        (projectData.projectType === "GENERAL_OPTIMIZATION" &&
          projectData.evaluatorId === "")
      ) {
        toast.error("Please ensure all fields are filled out.");
        return;
      }

      if (
        projectData.projectType === "SATELLITE_CONSTELLATION_OPTIMIZATION" &&
        projectData.activeNode === "CONSTELLATION"
      ) {

        if (
          projectData.numberOfTargets === undefined ||
          projectData.numberOfTargets <= 0
        ) {
          toast.error("Please enter a valid Number of Satellites.");
          return;
        }

        if (projectData.isManualMode) {
          const { coverageRatio, target, satellite } =
            projectData.manualValues || {};
          if (!coverageRatio || !target || !satellite) {
            toast.error("Please fill in all manual input fields.");
            return;
          }
        }
        else if (file === null) {
          toast.info("Please Upload files.");
          const container = fieldContainer.current;
          container.scroll({ top: container.scrollHeight, behavior: "smooth" });
          return;
        }
      }

      if (
        projectData.evaluatorRequireFile &&
        file === null &&
        !projectData.isManualMode
      ) {
        toast.info("Problem file required.");
        const container = fieldContainer.current;
        container.scroll({ top: container.scrollHeight, behavior: "smooth" });
        return;
      }

      submitProject();
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
      e.preventDefault();
      createProject();
    };

    const onFileChange = (file: any) => {
      setFile(file);
    };

    const onManualModeChange = (isOn: boolean) => {
      setProjectData((prev) => ({
        ...prev,
        isManualMode: isOn,
      }));
    };

    const onManualValueChange = (field: string, value: string) => {
      setProjectData((prev) => {
        const defaultManualValues = {
          coverageRatio: "",
          target: "",
          satellite: "",
        };

        const currentValues = prev.manualValues || defaultManualValues;
        return {
          ...prev,
          manualValues: {
            ...currentValues,
            [field]: value,
          },
        };
      });
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (validationErrors.name && e.target.value.trim() !== "") {
        setValidationErrors((prev) => ({ ...prev, name: false }));
      }
      setProjectData({ ...projectData, name: e.target.value });
    };

    return (
      <div
        ref={ref}
        className="fixed inset-0 backdrop-blur-sm bg-opacity-50 bg-black flex items-center justify-center z-50"
      >
        <Toaster richColors />
        <div className="bg-white bg-opacity-95 rounded-lg p-6 w-full max-w-2xl">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold">Add Project(s)</h2>
            <button
              type="button"
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 text-2xl"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>
          <hr className="my-3" />
          <form onSubmit={handleSubmit} className="space-y-3">
            <div
              ref={fieldContainer}
              className="space-y-3 max-h-[410px] overflow-auto"
            >
              <div>
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium"
                >
                  Name
                </label>
                <input
                  id="name"
                  type="text"
                  placeholder="Enter the project name here"
                  className={`w-full p-2 border rounded-md text-sm shadow-inner ${validationErrors.name
                      ? "border-red-500 focus:outline-none focus:ring-2 focus:ring-red-200"
                      : ""
                    }`}
                  value={projectData.name}
                  onChange={handleNameChange}
                />
                {validationErrors.name && (
                  <p className="mt-1 text-sm text-red-500">
                    Project name is required
                  </p>
                )}
              </div>

              <SimulationTypeSelector
                projectData={projectData}
                onSelect={handleSimulationTypeSelect}
              />

              {projectData.projectType !== "GENERAL_OPTIMIZATION" && (
                <TreeStructure
                  projectData={projectData}
                  treeNodes={treeNodes}
                  collapsedNodes={collapsedNodes}
                  toggleNode={toggleNode}
                  onFileChange={onFileChange}
                  onTargetsChange={(value) =>
                    setProjectData((prev) => ({
                      ...prev,
                      numberOfTargets: value,
                    }))
                  }
                  onManualModeChange={onManualModeChange}
                  onManualValueChange={onManualValueChange}
                />
              )}

              {projectData.projectType === "GENERAL_OPTIMIZATION" && (
                <EvaluatorsList
                  isLoading={isLoading}
                  error={error}
                  evaluators={evaluators}
                  projectData={projectData as any}
                  handleEvaluatorSelect={handleEvaluatorSelect}
                />
              )}

              {projectData.projectType === "GENERAL_OPTIMIZATION" &&
                projectData.evaluatorRequireFile && (
                  <MultiFileUpload onFileChange={onFileChange} />
                )}
            </div>
            <AddProject isSubmitting={isSubmitting} />
          </form>
        </div>
      </div>
    );
  }
);

ProjectModal.displayName = "ProjectModal";
export default ProjectModal;

import axios from "axios";
import { getkc, getUserId } from "./KeycloakService";
import { backendUrl } from "../routes/BaseUrl";
import { store } from "../store";

const backendAPI = axios.create({
  baseURL: backendUrl,
});


const interceptor = (config: any) => {
  const accessToken = getkc()?.token;
  const tenantId = store.getState().tenant.active;
  config.headers.Authorization = `Bearer ${accessToken}`;
  config.headers.userId = getUserId();
  config.headers.tenantId = tenantId;
  return config;
};

backendAPI.interceptors.request.use(interceptor);

export function isUserAdmin(): boolean {
  const activeTenant = store.getState().tenant.active;
  const kc = getkc();
  if (
    kc &&
    kc.tokenParsed &&
    Array.isArray(kc.tokenParsed.tenant_roles) &&
    activeTenant
  ) {
    return kc.tokenParsed.tenant_roles.some((roleObj: any) => {
      return roleObj[activeTenant]?.includes("USER_ADMIN");
    });
  }
  return false;
}

export default backendAPI;

import { FolderX, Trash2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast, Toaster } from "sonner";
import { resetEvaluatorArguments } from "../../../redux/evaluatorSlice";
import { resetExecutionParams } from "../../../redux/executionSlice";
import { resetGlobalCheck } from "../../../redux/globalCheckSlice";
import { useAppSelector } from "../../../redux/hooks";
import { resetServerId } from "../../../redux/serverSlice";
import backendAPI from "../../../services/apiRequestService";
import {
  ExecutionStatus,
  Project,
  ProjectsTableProps,
} from "../../../types/types";
import { getDateFromTimestamp } from "../../common/DateAndTime";
import EmptyList from "../../common/EmptyList";
import ConfirmationModal from "../../common/popup/ConfirmationModal";
import ExecutionDetails from "./ExecutionDetails";
import ProjectModal from "./ProjectModal";
import Switch from "./components/Switch";


const StyleStatus = ({status}:any) => {
  return (<>
    {{
      "ACTIVE":<p className=" px-3 py-1 text-center bg-green-100 text-green-800 text-sm rounded-md font-medium w-fit">ACTIVE</p>,
      "UPLOAD_FILE":<p className=" px-3 py-1 text-center bg-red-100 text-red-800 text-sm font-medium rounded-md w-fit ">UPLOAD_FILE</p>,
    }[String(status)]}
    </>
  )
}



const ProjectsTable: React.FC<ProjectsTableProps> = ({
  onProjectSelect,
  onProjectUpdate,
}) => {
  const dispatch = useDispatch();
  const [projects, setProjects] = useState<Project[]>([]);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showServerDetailsModal, setShowServerDetailsModal] =
      useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [projectToDelete, setProjectToDelete] = useState<Project | null>(
      null
    );
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [expandedProjectId, setExpandedProjectId] = useState<number | null>(
    null
  );
  const [projectErrorCode, setProjectErrorCode] = useState<number>(0)
  const activeTenantId = useAppSelector((state) => state.tenant.active);

  const fetchProject = async () =>{
    setProjectErrorCode(0)
    setProjects([]);
    backendAPI
      .get("/project-management/projects?status=UPLOAD_FILE&status=ACTIVE") 
      .then((response) => {
        const projects = response.data
          .map((project: any) => ({
            id: project.id,
            name: project.name,
            projectType: project.projectType.type,
            status:project.status,
            
            evaluatorId: project.evaluator ? project.evaluator.id : null,
            evaluatorName: project.evaluator ? project.evaluator.name : "N/A",
            evaluatorFileName: project.evaluator ? project.evaluator.fileName : null,
            evaluatorFunctionName: project.evaluator ? project.evaluator.functionName : null,
            evaluatorLanguage: project.evaluator ? project.evaluator.language : null,
            dateCreated: getDateFromTimestamp(project.createdAt),
            executions: [],
          }));
        setProjectErrorCode(200)
          setProjects(projects);
          
      })
      .catch((error) => {
        const errorCode = error?.response?.status;
        errorCode ? setProjectErrorCode(errorCode) : setProjectErrorCode(500)
      });
  }

  useEffect(() => {
    fetchProject()
  }, [activeTenantId]);

  useEffect(() => {
    onProjectUpdate?.(projects);
  }, [onProjectUpdate, projects]);

  useEffect(() => {
    onProjectSelect?.(selectedProject);
  }, [onProjectSelect, selectedProject]);
    
 const handleDeleteProject = async (e: React.MouseEvent, project: Project) => {
   e.stopPropagation();
   setProjectToDelete(project);
   setShowDeleteModal(true);
 };

 const confirmDelete = async () => {
   if (!projectToDelete) return;

   try {
     await backendAPI.delete(
       `/project-management/projects/${projectToDelete.id}`
     );
     fetchProject()
     if (expandedProjectId === projectToDelete.id) {
       setExpandedProjectId(null);
       setSelectedProject(null);
     }
     toast.success("Project deleted successfully");
   } catch (error) {
     toast.error("Failed to delete project");
     console.error("Error:", error);
   }
   setShowDeleteModal(false);
   setProjectToDelete(null);
 };

  const fetchExecutions = async (projectId: string) => {
    try {
      const response = await backendAPI.get(
        `/project-management/projects/${projectId}/executions`
      );
      const executions = response.data.map((execution: any) => ({
        id: execution.id,
        name: `Execution ${execution.id}`,
        generations: 0,
        statusReason: execution.statusReason,
        status: execution.status.toUpperCase() as ExecutionStatus,
        dateCreated: getDateFromTimestamp(execution.startedAt, true, true),
        serverName: execution.server
      }));
      return executions;
    } catch (error: any) {
      console.info("Error fetching executions: ", error);
      return [];
    }
  };

  const handleProjectClick = async (activeProject: any): Promise<void> => {
    const project = projects.find((p) => p.id === activeProject.id);
    if (!project || project.status === "UPLOAD_FILE") return;
 
    if (expandedProjectId === project.id) {
      setExpandedProjectId(null);
      setSelectedProject(null);
      return;
    }
    try {
      const executions = await fetchExecutions(project.id.toString());
      if (executions.length < 1) {
        toast.info(`No Execution Found for project : ${project.name}`);
        return;
      }

      const updatedProjects = projects.map((p) =>
        p.id === activeProject.id ? { ...p, executions } : p
      );
      setProjects(updatedProjects);
      setExpandedProjectId(activeProject.id);
        setSelectedProject({ ...project, executions });
       
    } catch (error) {
      console.error("Error handling project click:", error);
    }
  };

  const handleAddProject = (): void => {
    fetchProject()
  };

  const showExecute = (e: any, project: any) => {
    e.stopPropagation();
    setSelectedProject({ ...project, executions: [] });
    setShowServerDetailsModal(true);
  };

  const handleServerDetailsNext = (): void => {
    setShowServerDetailsModal(false);
  };

  const onClose = () => {
    localStorage.removeItem("selectedServer")
      dispatch(resetEvaluatorArguments());
      dispatch(resetExecutionParams());
      dispatch(resetServerId());
      dispatch(resetGlobalCheck());
    setExpandedProjectId(null);
    setSelectedProject(null);
    setShowServerDetailsModal(false);
  };

  const handleExecute = (e:any, project:any) =>{
    showExecute(e, project);
    localStorage.removeItem('filled')
  }
  
  const handleAddProjectBtn = (): void => {
    setShowCreateModal(true);
  };

  return (
    <>
      <div className="bg-gray-50 shadow-inner">
        <Toaster richColors />
        <div className="flex justify-between items-center p-4">
          <h2 className="text-lg font-bold">
            Total Projects - {projects.length > 0 ? projects.length : "0"}
          </h2>
          <button
            type="button"
            className="bg-black text-white px-4 py-2 rounded-md flex items-center"
            onClick={handleAddProjectBtn}
          >
            + Create Project
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-100 text-gray-500">
                <th className="px-4 py-3 font-medium text-left">Name</th>
                <th className="px-4 py-3 font-medium text-left">Type</th>
                <th className="px-4 py-3 font-medium text-left">Evaluator</th>
                <th className="px-4 py-3 font-medium text-left">
                  Date Created
                </th>
                <th className="px-4 py-3 font-medium text-center w-[200px]">
                  Status
                </th>
                <th className="px-4 py-3 pr-10 font-medium text-right w-[200px]">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project, index) => (
                <React.Fragment key={project.id}>
                  <tr
                    onClick={() => handleProjectClick(project)}
                    className={`border-b hover:bg-gray-100 cursor-pointer select-none ${
                      index % 2 === 0 ? "bg-white" : "bg-gray-50"
                    } ${expandedProjectId === project.id ? "bg-blue-50" : ""}`}
                  >
                    <td className="p-4 pl-4 py-3">{project.name}</td>
                    <td className="px-4 py-3">{project.projectType}</td>
                    <td className="px-4 py-3">{project.evaluatorName}</td>
                    <td className="px-4 py-3">{project.dateCreated}</td>
                    <td className="px-4 py-3 text-center flex items-start justify-center">
                      <StyleStatus status={project.status} />
                    </td>
                    <td className="px-4 py-3 text-left w-1">
                      <div className="flex items-center gap-4">
                        <button
                          type="button"
                          onClick={(e) => handleExecute(e, project)}
                          disabled={project.status === "UPLOAD_FILE"}
                          className={`bg-red-500 text-white px-4 py-1 rounded-md hover:bg-red-600 ${
                            project.status === "UPLOAD_FILE" &&
                            " opacity-50 pointer-events-none"
                          }`}
                        >
                          Execute
                        </button>
                        <button
                          type="button"
                          onClick={(e) => handleDeleteProject(e, project)}
                          className="p-1 hover:bg-gray-200 rounded-md ml-4"
                        >
                          <Trash2 size={20} className="text-gray-600" />
                        </button>
                      </div>
                    </td>
                  </tr>
                  {expandedProjectId === project.id && (
                    <tr className="expanded-row">
                      <td colSpan={6} className="p-0 border-b">
                        <div className="bg-gray-50">
                          <ExecutionDetails
                            project={project}
                            executions={project.executions}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        {showServerDetailsModal && selectedProject && (
          <Switch
            project={selectedProject}
            onClose={onClose}
            onNext={handleServerDetailsNext}
          />
        )}
      </div>
      <EmptyList
        selector={projectErrorCode}
        logo={<FolderX size={50} className=" text-gray-800" />}
        title="No Projects Available"
        subtitle="You haven't created any projects yet. Start by adding a new project."
        showSupportMail={false}
        button={"Create New Project"}
        onClick={handleAddProjectBtn}
      />
      {showCreateModal && (
        <ProjectModal
          onClose={() => setShowCreateModal(false)}
          onAdd={handleAddProject}
        />
      )}
      {showDeleteModal && (
        <ConfirmationModal
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={confirmDelete}
          title="Delete Project"
          message="Are you sure you want to delete this project? This action cannot be undone."
        />
      )}
    </>
  );
};

export default ProjectsTable;


import React, { useState, useEffect } from "react";
import { toast } from "sonner";
import { BusinessRole, RoleDetail, PolicyRule } from "./types";

interface RoleDetailsDisplayProps {
  selectedBusinessRole: BusinessRole;
  roleDetails: RoleDetail | null;
  availableFunctionalRoles: PolicyRule[];
  isLoading: boolean;
  searchRoleTerm: string;
  setSearchRoleTerm: (term: string) => void;
  onUpdateRole: (finalRoles: string[]) => void;
  onDeleteRole?: () => void;
}

const RoleDetailsDisplay: React.FC<RoleDetailsDisplayProps> = ({
  selectedBusinessRole,
  roleDetails,
  availableFunctionalRoles,
  isLoading,
  searchRoleTerm,
  setSearchRoleTerm,
  onUpdateRole,
  onDeleteRole,
}) => {
  const [assignedRoles, setAssignedRoles] = useState<string[]>([]);
  const [rolesToRemove, setRolesToRemove] = useState<Set<string>>(new Set());
  const [rolesToAdd, setRolesToAdd] = useState<Set<string>>(new Set());
  const [originalRoles, setOriginalRoles] = useState<string[]>([]);

  useEffect(() => {
    if (roleDetails) {
      setAssignedRoles(roleDetails.functionalRoles);
      setOriginalRoles(roleDetails.functionalRoles);
      setRolesToRemove(new Set());
      setRolesToAdd(new Set());
    }
  }, [roleDetails]);

  const toggleRemove = (roleId: string) => {
    setRolesToRemove((prev) => {
      const copy = new Set(prev);
      if (copy.has(roleId)) {
        copy.delete(roleId);
        toast.success("Unmarked role for removal");
      } else {
        copy.add(roleId);
        toast.error("Marked role for removal");
      }
      return copy;
    });
  };

  const toggleAdd = (roleId: string) => {
    setRolesToAdd((prev) => {
      const copy = new Set(prev);
      if (copy.has(roleId)) {
        copy.delete(roleId);
        toast.info("Removed role from additions");
      } else {
        copy.add(roleId);
        toast.success("Marked role for addition");
      }
      return copy;
    });
  };

  const handleUpdateChanges = () => {
    const finalAssigned = assignedRoles
      .filter((r) => !rolesToRemove.has(r))
      .concat(Array.from(rolesToAdd));
    onUpdateRole(finalAssigned);
    toast.success("Updated role changes");
  };

  const handleDiscardChanges = () => {
    setAssignedRoles(originalRoles);
    setRolesToRemove(new Set());
    setRolesToAdd(new Set());
    toast.info("Discarded changes");
  };

  const changesMade = rolesToRemove.size > 0 || rolesToAdd.size > 0;

  const unassigned = availableFunctionalRoles.filter(
    (r) => !assignedRoles.includes(r.id)
  );

  if (!roleDetails) {
    return (
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200 flex items-center justify-center h-64">
        <div className="text-center">
          <svg
            className="w-12 h-12 text-gray-300 mx-auto mb-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 012 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
            />
          </svg>
          <h3 className="text-lg font-medium text-gray-500">
            Select a role or create a new one
          </h3>
          <p className="text-gray-400 mt-1">
            Choose a business role to view and manage its permissions
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold">
          Role Details: {selectedBusinessRole.businessRole}
        </h2>
        <div className="flex items-center space-x-3">
          {changesMade && (
            <button
              type="button"
              onClick={handleDiscardChanges}
              className="text-gray-500 hover:text-gray-700"
              title="Discard Changes"
            >
              <svg
                className="w-4 h-4 mr-1 text-gray-600"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}
          {!changesMade && onDeleteRole && (
            <button
              type="button"
              onClick={onDeleteRole}
              className="text-sm px-3 py-1 rounded-md text-red-600 hover:bg-red-200 flex items-center"
              title="Delete this role"
            >
              <svg
                className="w-4 h-4 mr-1 text-red-600"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V5a2 2 0 00-2-2H9a2 2 0 00-2 2v2h10z"
                />
              </svg>
              Delete Role
            </button>
          )}
          {changesMade && (
            <button
              type="button"
              onClick={handleUpdateChanges}
              className="bg-blue-600 text-white px-3 py-1 rounded-md hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
              disabled={isLoading}
            >
              {isLoading ? "Updating..." : "Update Changes"}
            </button>
          )}
        </div>
      </div>

      <div className="space-y-6">
        <div>
          <div className="flex justify-between items-center mb-3">
            <h3 className="text-md font-medium text-gray-700">
              Assigned Functional Roles
            </h3>
            <span className="text-sm bg-blue-100 text-blue-800 px-2 py-1 rounded-full">
              {assignedRoles.length} assigned
            </span>
          </div>
          {assignedRoles.length === 0 ? (
            <div className="bg-gray-50 p-4 rounded-md mb-4">
              <p className="text-gray-500 italic text-sm text-center">
                No functional roles assigned yet
              </p>
            </div>
          ) : (
            <div className="mb-6">
              <div className="flex flex-wrap gap-2">
                {assignedRoles.map((roleId) => {
                  const roleInfo = availableFunctionalRoles.find(
                    (r) => r.id === roleId
                  );
                  const isMarkedForRemoval = rolesToRemove.has(roleId);
                  return (
                    <div
                      key={roleId}
                      className={`inline-flex items-center px-3 py-1 rounded-full text-sm group relative ${
                        isMarkedForRemoval
                          ? "bg-red-50 text-red-700 line-through border border-red-300"
                          : "bg-blue-50 text-blue-700"
                      }`}
                    >
                      <span title={roleInfo?.description || ""}>
                        {roleInfo ? roleInfo.name : roleId}
                      </span>
                      <button
                        type="button"
                        onClick={() => toggleRemove(roleId)}
                        className={`ml-2 flex items-center justify-center w-4 h-4 rounded-full hover:bg-blue-200 ${
                          isMarkedForRemoval
                            ? "text-red-700"
                            : "text-blue-700"
                        }`}
                      >
                        {isMarkedForRemoval ? "Undo" : "x"}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <div>
          <div className="flex justify-between items-center mb-3">
            <h3 className="text-md font-medium text-gray-700">
              Available Functional Roles
            </h3>
            <span className="text-sm bg-gray-100 text-gray-800 px-2 py-1 rounded-full">
              {unassigned.length} available
            </span>
          </div>
          <div className="mb-3">
            <div className="relative">
              <input
                type="text"
                placeholder="Search for functional roles..."
                value={searchRoleTerm}
                onChange={(e) => setSearchRoleTerm(e.target.value)}
                className="w-full pl-9 pr-3 py-2 border border-gray-300 rounded-md"
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg
                  className="h-4 w-4 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="border border-gray-200 rounded-md bg-white p-3 max-h-72 overflow-y-auto">
            {unassigned
              .filter(
                (role) =>
                  role.name
                    .toLowerCase()
                    .includes(searchRoleTerm.toLowerCase()) ||
                  (role.description &&
                    role.description
                      .toLowerCase()
                      .includes(searchRoleTerm.toLowerCase()))
              )
              .map((role) => {
                const isMarkedForAddition = rolesToAdd.has(role.id);
                return (
                  <div
                    key={role.id}
                    className={`flex items-center justify-between p-2 rounded mb-1 ${
                      isMarkedForAddition
                        ? "bg-green-50 border border-green-200"
                        : "hover:bg-gray-50"
                    }`}
                  >
                    <div className="flex items-center">
                      <div className="mr-2 text-sm text-gray-700">
                        {role.name}
                      </div>
                      {role.description && (
                        <div className="text-xs text-gray-400">
                          {role.description}
                        </div>
                      )}
                    </div>
                    <button
                      type="button"
                      onClick={() => toggleAdd(role.id)}
                      className={`text-sm px-3 py-1 rounded ${
                        isMarkedForAddition
                          ? "bg-green-100 text-green-700 hover:bg-green-200"
                          : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                      }`}
                    >
                      {isMarkedForAddition ? "Undo" : "Add"}
                    </button>
                  </div>
                );
              })}
            {unassigned.length === 0 && (
              <p className="text-gray-500 text-sm italic">
                No unassigned roles found
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleDetailsDisplay;

import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import JSZip from "jszip";
import Papa from "papaparse";
import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { useNavigate, useParams } from "react-router-dom";
import backendAPI from "../../../../services/apiRequestService";
import Detail from "./ExecutionDetails";
import DesignVariables from "./DesignVariables";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface CsvRow {
  "Generation Id"?: string;
  "Design Variable"?: string;
  [key: string]: string | undefined;
}

export const getErrorMessageStyles = (status: string): string => {
  switch (status) {
    case "RUNNING":
      return "text-blue-800";
    case "FAILED":
      return "text-red-800";
    case "CREATED":
      return "text-blue-800";
    default:
      return "text-gray-600";
  }
};

const Skelton = () => <div className="animate-pulse space-y-4 w-full">
    <div className="bg-gray-300 h-3 w-32 rounded"></div>
    <div className="bg-gray-200 h-[5cm] w-full rounded"></div>
    <div className="flex gap-2 justify-center">
      <div className="bg-gray-200 h-4 w-12 rounded"></div>
      <div className="bg-gray-300 h-4 w-12 rounded"></div>
      <div className="bg-gray-100 h-4 w-12 rounded"></div>
      <div className="bg-gray-200 h-4 w-12 rounded"></div>
    </div>
  </div>


const ExecutionGraphPage: React.FC = () => {
  const chartRef = useRef<any>(null);
  const trialColors: { [key: number]: string } = {};
  const usedColors = new Set<string>();
  const [execution, setExecution] = useState<any>(null);
  const { executionId, projectId } = useParams();
  const [csvData, setCsvData] = useState<CsvRow[] | null>(null);
  const [designVariableData, setDesignVariableData] = useState<CsvRow[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [numTrials, setNumTrials] = useState<number>(0);
  const [selectedTrials, setSelectedTrials] = useState<number[]>([]);

  const navigate = useNavigate();

  const fetchExecutions = async (projectId: string, executionId: string) => {
    try {
      const response = await backendAPI.get(
        `/project-management/projects/${projectId}/executions`
      );
      const executionItem = response.data.find((execution: any) => (execution.id === executionId));
      if (executionItem) {
        return executionItem;
      } else {
        navigate("/projects");
      }
    } catch (error: any) {
      navigate("/projects");
      console.error("Error fetching execution:", error);
    }
  };
  const fetchProject = async (projectId: string) => {
    try {
      const response = await backendAPI.get(
        `/project-management/projects/${projectId}`
      );
        return response.data;
    } catch (error: any) {
      navigate("/projects");
      console.error("Error fetching project:", error);
    }
  };

  const fetchZipFile = async () => {
    try {
      const response = await backendAPI.get(
        `/project-management/projects/${projectId}/executions/${executionId}/downloadResult`,
        { responseType: "blob" }
      );

      const zip = new JSZip();
      const zipContent = await zip.loadAsync(response.data);

      const fitnessFile = Object.values(zipContent.files).find((file) =>
        file.name.startsWith("results/output_GenidVSFitness")
      );

      const designVariableFile = Object.values(zipContent.files).find(
        (file) => file.name.startsWith("results/output_DesignVariable")
      );

      if (fitnessFile) {
        const content = await fitnessFile.async("text");
        parseFitnessCSV(content);
      } else {
        setErrorMessage("Fitness file not found.");
      }
      if (designVariableFile) {
        const content = await designVariableFile.async("text");
        parseDesignVariableCSV(content);
      } else {
        setErrorMessage("Design Variable file not found.");
      }
    } catch (error) {
      setErrorMessage("Error reading the zip file");
      console.error(error);
    }
  };

  useEffect(() => {
    setExecution(null)
    if (projectId && executionId) {
      fetchExecutions(projectId, executionId)
        .then((execution) => {
          if (execution) {
            fetchProject(projectId)
              .then((project) => {
                setExecution({...execution, project:project});
                })
                .catch((error) => {
                  console.error("Failed to fetch project:", error);
                });

            switch (execution.status) {
              case "RUNNING":
                setErrorMessage(
                  "Execution is still running. Please wait until it is completed."
                );
                return;
              case "FAILED":
                setErrorMessage("Execution has failed.");
                return;
              case "CREATED":
                setErrorMessage(
                  "Execution will be scheduled shortly. Kindly wait until it is completed."
                );
                return;
              case "COMPLETED":
                fetchZipFile();
                return;
            }
          }
        });
    } else {
      navigate("/projects")
      console.info("Having issue : ", projectId, " : ", executionId)
    }
  }, []);

  const parseFitnessCSV = (content: string) => {
    Papa.parse<CsvRow>(content, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        setCsvData(result.data);
        setErrorMessage(null);

        const trialColumns = Object.keys(result.data[0]).filter((key) =>
          key.includes("Best Fitness Trial")
        );
        setNumTrials(trialColumns.length);
        setSelectedTrials(
          Array.from({ length: trialColumns.length }, (_, i) => i + 1)
        );
      },
    });
  };

  const parseDesignVariableCSV = (content: string) => {
    Papa.parse<CsvRow>(content, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        setDesignVariableData(result.data);
      },
    });
  };

  const generateChartData = () => {
    if (csvData?.length === 0) return { labels: [], datasets: [] };

    let maxValidIndex = 0;
    let hue = 200;

    const datasets = Array.from({ length: numTrials }).map((_, trialIndex) => {
      const trial = trialIndex + 1;
      if (!trialColors[trial]) {
        let newColor;
        do {
          hue = (hue + 137.508) % 360;
          newColor = `hsl(${hue}, 70%, 50%)`;
        } while (usedColors.has(newColor));

        trialColors[trial] = newColor;
        usedColors.add(newColor);
      }

      const validData = csvData?.map((row, index) => {
        const generationId = parseFloat(row["Generation Id"]!);
        const bestFitness = parseFloat(row[`Best Fitness Trial ${trial}`]!);

        if (!isNaN(bestFitness)) {
          maxValidIndex = Math.max(maxValidIndex, index);
        }

        return { generationId, bestFitness };
      })
        .filter(
          (data) => !isNaN(data.generationId) && !isNaN(data.bestFitness)
        );

      const isTrialSelected = selectedTrials.includes(trial);

      return {
        label: `Trial ${trial}`,
        data: isTrialSelected ? validData?.map((data) => data.bestFitness) : [],
        borderColor: trialColors[trial],
        backgroundColor: trialColors[trial],
        borderWidth: 2,
        tension: 0.4,
        fill: false,
        hidden: !isTrialSelected,
      };
    });

    const labels = csvData?.slice(0, maxValidIndex + 1)
      .map((row) => parseFloat(row["Generation Id"]!))
      .filter((genId) => !isNaN(genId));

    return { labels, datasets };
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.update();
    }
  }, [selectedTrials]);

  const toggleAllTrials = () => {
    setSelectedTrials((prevSelected) => {
      if (prevSelected.length === numTrials) {
        return [];
      } else {
        return Array.from({ length: numTrials }, (_, i) => i + 1);
      }
    });
  };

  const chartOptions: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: "Generation Id",
        },
        ticks: {
          stepSize: 70,
          align: "center",
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        title: {
          display: true,
          text: "Best Fitness Value",
        },
        beginAtZero: false,
      }
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        onClick: (e, legendItem) => {
          const datasetIndex = legendItem.datasetIndex;
          if (datasetIndex === undefined) {
            return;
          }

          const chart = chartRef.current;
          const dataset = chart.data.datasets[datasetIndex];
          dataset.hidden = !dataset.hidden;
          if (dataset.hidden) {
            setSelectedTrials((prevSelected) =>
              prevSelected.filter((t) => t !== datasetIndex + 1)
            );
          } else {
            setSelectedTrials((prevSelected) => [
              ...prevSelected,
              datasetIndex + 1,
            ]);
          }
          chart.update();
        },
      },
    },
  };

  return (
    <div className="p-4 flex flex-col items-center relative">
      <Detail execution={execution} />
      <hr className="w-full border-gray-30 my-4" />

      <DesignVariables errorMessage={errorMessage} execution={execution} designVariableData={designVariableData} numTrials={numTrials} />

      <hr className="w-full border-gray-300 my-4" />

      <div className="flex flex-col w-full ">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold mb-4">
            Generation vs Best Fitness
          </h2>
          {csvData && csvData.length > 0 ?
            <label htmlFor="selectAll" className="font-semibold flex items-center space-x-2 mr-12">
              <input
                id="selectAll"
                type="checkbox"
                checked={selectedTrials.length === numTrials}
                onChange={toggleAllTrials}
                className="mr-2" /> Select All / Deselect All Trials
            </label> : ""
          }
        </div>
        {errorMessage ?
          <div
            className={`font text-left py-2 tracking-wider rounded-md ${getErrorMessageStyles(
              execution?.status || ""
            )}`}
          >
            {errorMessage}
          </div>
          :
          <div className="">
            {csvData ?
              <div
                className="bg-white rounded py-2 shadow"
                style={{ height: "13cm" }}
              >
                <Line
                  data={generateChartData()}
                  options={chartOptions}
                  ref={chartRef}
                />
              </div>
              : <Skelton />
            }
          </div>
        }
      </div>
    </div>
  );
};

export default ExecutionGraphPage;

import React, { useState } from "react";
import { ExtendedProjectData } from "../../types";
import { Info, Upload } from "lucide-react";
import SingleFileUpload from "../../../../common/SingleFileUpload";

interface LogisticsNodeProps {
  projectData: ExtendedProjectData;
  onTreeFileChange: (files: any) => void;
  onCostVectorChange: (files: any) => void;
  onSitesChange: (value: number | undefined) => void;
  onProductsChange: (value: number | undefined) => void;
}

const LogisticsNode: React.FC<LogisticsNodeProps> = ({
  projectData,
  onTreeFileChange,
  onCostVectorChange,
  onSitesChange,
  onProductsChange,
}) => {
  const [treeTooltip, setTreeTooltip] = useState(false);
  const [sitesTooltip, setSitesTooltip] = useState(false);
  const [productsTooltip, setProductsTooltip] = useState(false);
  const [costTooltip, setCostTooltip] = useState(false);

  const [sitesInputValue, setSitesInputValue] = useState(
    projectData.numberOfSites?.toString() || ""
  );
  const [productsInputValue, setProductsInputValue] = useState(
    projectData.numberOfProducts?.toString() || ""
  );

  const handleSitesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSitesInputValue(value);

    if (value === "") {
      onSitesChange(undefined);
    } else {
      const numValue = parseInt(value, 10);
      if (!isNaN(numValue)) {
        onSitesChange(numValue);
      }
    }
  };

  const handleProductsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setProductsInputValue(value);

    if (value === "") {
      onProductsChange(undefined);
    } else {
      const numValue = parseInt(value, 10);
      if (!isNaN(numValue)) {
        onProductsChange(numValue);
      }
    }
  };


  const handleTreeFileChange = (file: File | null) => {
    if (file) {
      onTreeFileChange([file]);
    } else {
      onTreeFileChange([]);
    }
  };

  const handleCostVectorFileChange = (file: File | null) => {
    if (file) {
      onCostVectorChange([file]);
    } else {
      onCostVectorChange([]);
    }
  };

  return (
    <div className="space-y-4">
      {/* <div>
        <div className="flex items-center gap-2 mb-1">
          <label className="text-sm font-medium text-gray-700">
            Number of Sites
          </label>
          <div className="relative">
            <Info
              className="w-4 h-4 text-gray-500 cursor-help"
              onMouseEnter={() => setSitesTooltip(true)}
              onMouseLeave={() => setSitesTooltip(false)}
            />
            {sitesTooltip && (
              <div className="absolute left-6 -top-2 w-48 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                Total number of locations in your supply chain
              </div>
            )}
          </div>
        </div>
        <input
          type="number"
          min={1}
          placeholder="e.g. 10"
          className="w-full p-2 border border-gray-300 rounded-lg text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
          value={sitesInputValue}
          onChange={handleSitesChange}
        />
      </div>

      <div>
        <div className="flex items-center gap-2 mb-1">
          <label className="text-sm font-medium text-gray-700">
            Number of Products
          </label>
          <div className="relative">
            <Info
              className="w-4 h-4 text-gray-500 cursor-help"
              onMouseEnter={() => setProductsTooltip(true)}
              onMouseLeave={() => setProductsTooltip(false)}
            />
            {productsTooltip && (
              <div className="absolute left-6 -top-2 w-48 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                Total number of products flowing through your supply chain
              </div>
            )}
          </div>
        </div>
        <input
          type="number"
          min={1}
          placeholder="e.g. 5"
          className="w-full p-2 border border-gray-300 rounded-lg text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
          value={productsInputValue}
          onChange={handleProductsChange}
        />
      </div> */}

      <div>
        <div className="flex items-center gap-2 mb-1">
          <label className="text-sm font-medium text-gray-700">
            Network Tree
          </label>
          <div className="relative">
            <Info
              className="w-4 h-4 text-gray-500 cursor-help"
              onMouseEnter={() => setTreeTooltip(true)}
              onMouseLeave={() => setTreeTooltip(false)}
            />
            {treeTooltip && (
              <div className="absolute left-6 -top-2 w-48 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                Upload a file with your supply chain network structure
              </div>
            )}
          </div>
        </div>
        <SingleFileUpload
          onFileChange={handleTreeFileChange}
          accept=".csv,.json,.txt"
        />
      </div>

      <div>
        <div className="flex items-center gap-2 mb-1">
          <label className="text-sm font-medium text-gray-700">
            Cost Vector CSV
          </label>
          <div className="relative">
            <Info
              className="w-4 h-4 text-gray-500 cursor-help"
              onMouseEnter={() => setCostTooltip(true)}
              onMouseLeave={() => setCostTooltip(false)}
            />
            {costTooltip && (
              <div className="absolute left-6 -top-2 w-52 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                Upload a CSV containing cost data for transportation between
                sites
              </div>
            )}
          </div>
        </div>
        <SingleFileUpload
          onFileChange={handleCostVectorFileChange}
          accept=".csv"
        />
      </div>

      {((projectData.treeFiles && projectData.treeFiles.length > 0) ||
        (projectData.costVectorFiles &&
          projectData.costVectorFiles.length > 0) ||
        projectData.numberOfSites ||
        projectData.numberOfProducts) && (
        <div className="mt-2 p-3 border border-blue-100 bg-blue-50 rounded-lg">
          <h3 className="text-sm font-medium text-gray-700 mb-2">
            Configuration Summary
          </h3>

          <div className="space-y-2 text-sm">
            {projectData.treeFiles && projectData.treeFiles.length > 0 && (
              <div className="flex justify-between">
                <span className="text-gray-600">Tree Files:</span>
                <span className="font-medium">
                  {projectData.treeFiles.length} file(s) uploaded
                </span>
              </div>
            )}

            {projectData.numberOfSites && projectData.numberOfSites > 0 && (
              <div className="flex justify-between">
                <span className="text-gray-600">Sites:</span>
                <span className="font-medium">{projectData.numberOfSites}</span>
              </div>
            )}

            {projectData.numberOfProducts &&
              projectData.numberOfProducts > 0 && (
                <div className="flex justify-between">
                  <span className="text-gray-600">Products:</span>
                  <span className="font-medium">
                    {projectData.numberOfProducts}
                  </span>
                </div>
              )}

            {projectData.costVectorFiles &&
              projectData.costVectorFiles.length > 0 && (
                <div className="flex justify-between">
                  <span className="text-gray-600">Cost Vector Files:</span>
                  <span className="font-medium">
                    {projectData.costVectorFiles.length} file(s) uploaded
                  </span>
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LogisticsNode;

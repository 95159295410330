import { X } from "lucide-react";
import React from "react";
import { Server } from "../ServerTable";
import { getHardware } from "./HardwaresList";

interface ViewServerDetailsProps {
  server: Server;
  onClose: () => void;
}

const ViewServerDetails: React.FC<ViewServerDetailsProps> = ({
  server,
  onClose,
}) => {
  const details = [
    { label: "ServerName", value: server.serverName},
    { label: "Host", value: server.host },
    { label: "Port", value: server.port.toString() },
    { label: "Username", value: server.userName },
    { label: "Hardware", value: getHardware(server.hardware).label },
    { label: "Status", value: server.status },
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-white bg-opacity-95 rounded-lg p-6 w-full max-w-2xl">
        <h2 className="text-lg font-medium mb-4 border-b pb-2">
          Server Details
        </h2>

        <div className="space-y-4">
          {details.map((detail) => (
            <div key={detail.label} className="flex items-center">
              <div className="w-1/3 font-medium text-gray-700">
                {detail.label}
              </div>
              <div className="w-2/3 p-2 border rounded-md bg-gray-50 text-gray- shadow-inner">
                {detail.value}
              </div>
            </div>
          ))}
        </div>

        <div className="pt-4 mt-4 border-t flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-1.5 rounded-md bg-teal-600 text-white hover:bg-teal-700 flex items-center gap-2"
          >
            <X className="h-4 w-4" />
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewServerDetails;

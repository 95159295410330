import React from "react";

interface StatCardProps {
  title: string;
  value: number;
  date?: string | null;
  bgColor?: string;
}

interface Execution {
  status: "Created" | "Scheduled" | "Running" | "Completed"  | "Failed" | "Deleted";
}

interface Project {
  name: string;
  executions: Execution[];
}

interface ProjectStatsProps {
  selectedProject?: Project | null;
  allProjects: Project[];
}

interface ProjectStatistics {
  total: number;
  completed: number;
  running: number;
  failed: number;
}

const StatCard: React.FC<StatCardProps> = ({
  title,
  value,
  date = null,
  bgColor = "bg-blue-50",
}) => (
  <div className={`${bgColor} rounded-lg p-4 mb-4`}>
    <div className="flex justify-between items-start">
      <div>
        <h3 className="text-sm font-medium text-gray-500">{title}</h3>
        <p className="text-2xl font-semibold mt-1">{value}</p>
      </div>
      {date && <span className="text-sm text-gray-500">{date}</span>}
    </div>
  </div>
);

const ProjectStats: React.FC<ProjectStatsProps> = ({
  selectedProject = null,
  allProjects,
}) => {
  const totalExecutions = allProjects.reduce(
    (sum, project) => sum + project.executions.length,
    0
  );

  const allExecutions = allProjects.flatMap((project) => project.executions);
  const totalCompleted = allExecutions.filter(
    (exec) => exec.status === "Completed"
  ).length;

  // Calculate selected project statistics if a project is selected
  const projectStats: ProjectStatistics | null = selectedProject
    ? {
        total: selectedProject.executions.length,
        completed: selectedProject.executions.filter(
          (exec) => exec.status === "Completed"
        ).length,
        running: selectedProject.executions.filter(
          (exec) => exec.status === "Running"
        ).length,
        failed: selectedProject.executions.filter(
          (exec) => exec.status === "Failed"
        ).length,
      }
    : null;

  return (
    <aside className="w-64 p-4 bg-white border-l">
      {/* Always visible overall statistics */}
      <div className="mb-6">
        <h2 className="text-lg font-semibold mb-4">Overall Statistics</h2>
        <StatCard
          title="Total Executions"
          value={totalExecutions}
          bgColor="bg-blue-50"
        />
        <StatCard
          title="Completed Executions"
          value={totalCompleted}
          bgColor="bg-green-50"
        />
      </div>

      {/* Project-specific statistics - Only visible when a project is selected */}
      {selectedProject && projectStats && (
        <div className="border-t pt-6">
          <h2 className="text-lg font-semibold mb-4">{selectedProject.name}</h2>
          <div className="grid grid-cols-1 gap-4">
            <StatCard
              title="Total Executions"
              value={projectStats.total}
              bgColor="bg-blue-50"
            />
            <StatCard
              title="Completed"
              value={projectStats.completed}
              bgColor="bg-green-50"
            />
            <StatCard
              title="Running"
              value={projectStats.running}
              bgColor="bg-amber-50"
            />
            <StatCard
              title="Failed"
              value={projectStats.failed}
              bgColor="bg-red-50"
            />
          </div>
        </div>
      )}
    </aside>
  );
};

export default ProjectStats;

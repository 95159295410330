import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GlobalCheckState {
  lowerBoundChecked: boolean;
  upperBoundChecked: boolean;
}

const initialState: GlobalCheckState = {
  lowerBoundChecked: true,
  upperBoundChecked: true,
};

const globalCheckSlice = createSlice({
  name: 'globalCheck',
  initialState,
  reducers: {
    setGlobalCheck: (state, action: PayloadAction<GlobalCheckState>) => {
      state.lowerBoundChecked = action.payload.lowerBoundChecked;
      state.upperBoundChecked = action.payload.upperBoundChecked;
    },
      resetGlobalCheck: (state) => {
        state.lowerBoundChecked = initialState.lowerBoundChecked;
        state.upperBoundChecked = initialState.upperBoundChecked;
      },
  },
});

export const { setGlobalCheck, resetGlobalCheck } = globalCheckSlice.actions;

export default globalCheckSlice.reducer;

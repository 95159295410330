import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface EvaluatorState {
  evaluatorArguments: string;
}

const initialState: EvaluatorState = {
  evaluatorArguments: '',
};

const evaluatorSlice = createSlice({
  name: 'evaluator',
  initialState,
  reducers: {
    setEvaluatorArguments(state, action: PayloadAction<string>) {
      state.evaluatorArguments = action.payload;
    },
    resetEvaluatorArguments(state) {
      state.evaluatorArguments = '';
    },
  },
});

export const { setEvaluatorArguments, resetEvaluatorArguments } = evaluatorSlice.actions;

export default evaluatorSlice.reducer;

import { InfoIcon } from 'lucide-react'

const FieldInfo = ({desc}:any) => {
  return (
    <div className=" relative group">
      <div className=" absolute left-8 -top-3 w-80 z-10 text-sm opacity-0 group-hover:opacity-100 bg-opacity-20 backdrop-blur-md p-2 pointer-events-none bg-slate-300 rounded-md shadow-md text-center text-gray-500">
        {desc}
        <span className=" absolute -left-2 top-4 w-0 h-0 border-y-8 border-y-transparent border-r-8 border-r-slate-300 opacity-40"></span>
      </div>
      <InfoIcon className=" p-1 cursor-pointer group-hover:opacity-100 opacity-60 hover:opacity-100" />
    </div>
  )
}

export default FieldInfo
import { useState } from "react";
import Header from "../../components/common/Header";
import Notes from "../../components/common/Notes";
import Sidebar from "../../components/common/Sidebar";
import EvaluatorTable from "../../components/layout/evaluator/EvaluatorTable";
import { getTenantList } from "../../services/KeycloakService";
import EmptyList from "../../components/common/EmptyList";

export interface SidebarProps {
  isOpen: boolean;
}

export interface HeaderProps {
  onToggleSidebar: () => void;
}

export const EvaluatorPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const tenants = getTenantList();

  return (
    <div className="flex bg-gray-50">
      <Sidebar isOpen={isSidebarOpen} />

      <div className="flex-1 flex flex-col shadow-inner ">
        <Header onToggleSidebar={handleToggleSidebar} />
        {tenants.length === 0 ? <EmptyList selector={"noTenant"}/>: <EvaluatorTable />}
      </div>

      <Notes />
    </div>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ExecutionParamsState {
  mutationProbability?: number | null | undefined;
  crossoverProbability?: number | null | undefined;
  populationSize?: number | null;
  designVariables?: number | null;
  maxGenerations?: number | null;
  debsApproach: string | null | undefined;
  deltaTheta?: number | null;
  functionToleranceLimit?: number | null;
  designVariableToleranceLimit?: number | null;
  typeOfOptimization: string;
  lowerBound?: string | null;
  upperBound?: string | null;
  trials?: number | null;
}

const initialState: ExecutionParamsState = {
  mutationProbability: null,
  crossoverProbability: null,
  populationSize: null,
  designVariables: null,
  maxGenerations: null,
  deltaTheta: null,
  debsApproach: "true",
  functionToleranceLimit: null,
  designVariableToleranceLimit: null,
  typeOfOptimization: "continuous",
  lowerBound: null,
  upperBound: null,
  trials: null,
};

const executionSlice = createSlice({
  name: 'executionParams',
  initialState,
  reducers: {
    setExecutionParams: (state, action: PayloadAction<ExecutionParamsState>) => {
      return { ...state, ...action.payload };
    },
    resetExecutionParams: () => initialState, 
  },
});

export const { setExecutionParams, resetExecutionParams } = executionSlice.actions;
export default executionSlice.reducer;

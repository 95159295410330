import React, { useRef, useEffect } from 'react';
import { Toaster } from 'sonner';
import { DownloadIcon, Eye, Loader, MoreVertical, Trash, Upload } from 'lucide-react';

interface MenuItemProps {
	name: string;
	icon: React.ReactNode;
	processing: boolean;
	disabled: boolean;
	onClick: () => void;
}

const MenuButton: React.FC<MenuItemProps> = ({ name, icon, processing, disabled, onClick }) => {
	const buttonClassNames = `${disabled ? "cursor-not-allowed opacity-70" : "hover:bg-gray-100"} 
    ${processing ? "cursor-wait opacity-70" : ""} 
    ${name === "Delete" ? " text-red-700 " : " text-gray-700"}
    text-sm w-32 text-left px-4 py-2 flex items-center`;

	return (
		<button
			className={buttonClassNames}
			onClick={onClick}
			disabled={disabled}
		>
			{processing ? <Loader className="w-4 h-4 mr-2 animate-spin" /> : icon}
			{name}
		</button>
	);
};

interface ThreeDotProps {
	visible?: Partial<{
		view?: boolean;
		upload?:boolean;
		delete?: boolean;
		download?: boolean;
	}>;
	processing?: {
		view?: boolean;
		upload?:boolean;
		delete?: boolean;
		download?: boolean;
	};
	disabled?: {
		view?: boolean;
		upload?:boolean;
		delete?: boolean;
		download?: boolean;
	};
	onAction: any;
	on: any;
	isOpen: any;
	toggleDropdown: any;
}

const ThreeDot: React.FC<ThreeDotProps> = React.memo(({
	visible = { view: false, upload: false, delete: false, download: false },
	processing = { view: false, upload: false, delete: false, download: false },
	disabled = { view: false, upload: false, delete: false, download: false },
	onAction,
	on,
	isOpen,
	toggleDropdown
}) => {
	const boxRef = useRef<HTMLDivElement>(null);

	const finalVisible = {
		view: visible.view ?? false,
		upload:visible.upload ?? false,
		delete: visible.delete ?? false,
		download: visible.download ?? false,
	};

	const finalProcessing = {
		view: processing.view ?? false,
		upload: processing.upload ?? false,
		delete: processing.delete ?? false,
		download: processing.download ?? false,
	};

	const finalDisabled = {
		view: disabled.view ?? false,
		upload: disabled.upload ?? false,
		delete: disabled.delete ?? false,
		download: disabled.download ?? false,
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
			toggleDropdown(null)
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (

		<div className="relative text-center">
			<Toaster richColors />
			<MoreVertical
				className="w-5 h-5 cursor-pointer text-gray-700"
				onClick={(event) => toggleDropdown(event)}
			/>
			{isOpen && (
				<div
					ref={boxRef}
					className="absolute bottom-0 -left-28 bg-white border rounded-md shadow-lg z-20"
				>
					{[
						{ name: "View", icon: <Eye className="w-4 h-4 mr-2" />, processing: finalProcessing.view, disabled: finalDisabled.view, visible: finalVisible.view },
						{ name: "Upload", icon: <Upload className="w-4 h-4 mr-2" />, processing: finalProcessing.upload, disabled: finalDisabled.upload, visible: finalVisible.upload },
						{ name: "Delete", icon: <Trash className="w-4 h-4 mr-2" />, processing: finalProcessing.delete, disabled: finalDisabled.delete, visible: finalVisible.delete },
						{ name: "Download", icon: <DownloadIcon className="w-4 h-4 mr-2" />, processing: finalProcessing.download, disabled: finalDisabled.download, visible: finalVisible.download },
					]
						.filter((option) => option.visible)
						.map((option) => (
							<MenuButton
								key={crypto.randomUUID()}
								name={option.name}
								icon={option.icon}
								processing={option.processing}
								disabled={option.disabled}
								onClick={() => onAction({ element: on, option: option.name.toLowerCase() })}
							/>
						))}
				</div>
			)}
		</div>
	);
});

ThreeDot.displayName = 'ThreeDot';
export default ThreeDot;

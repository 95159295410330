import { DownloadIcon, Loader } from 'lucide-react'
import React, { memo } from 'react'
import { toast } from 'sonner'
import backendAPI from '../../../services/apiRequestService'
import { AxiosError } from 'axios'

const DownloadBtn = ({ item, endpoint, style, title="Download" }: { item: { fileName: string}, endpoint: string, style?: string, title?: string }) => {
	const [loading, setLoading] = React.useState(false)

	const handleDownload = async () => {
		if (loading === true) {
			toast.loading("Please Wait! Downloading Already in a queue.")
			return
		}
		try {
			setLoading(true)
			const response = await backendAPI.get(endpoint, { responseType: 'blob' }
			);
			const blob = new Blob([response.data], { type: 'application/json' });
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = item.fileName;
			document.body.appendChild(link);
			link.click();
			link.remove();
			URL.revokeObjectURL(url);
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				toast.error(
					error.response?.data?.error ||
					"Evaluator file could not be downloaded."
				);
			} else {
				toast.error("An unknown error occurred. Please try again later.");
			}
		} finally {
			setLoading(false)
		}
	}

	return (
		<button onClick={handleDownload} className={`${style} hover:bg-gray-300  w-32 text-left px-4 py-2 text-gray-700  flex items-center
			${loading && 'cursor-wait opacity-70'}
		`}>
			{loading 
				?	<Loader className="w-4 h-4 mr-2 animate-spin" />
				: 	<DownloadIcon className="w-4 h-4 mr-2" />
			} {title}
		</button>
	)
}

export default memo(DownloadBtn)